import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, ShoppingBagIcon, XIcon } from "@heroicons/react/outline";
import { Link, useLocation } from "react-router-dom";
import { navigationStatic } from "../../utils/constants/navigations";
import logo from "../../assets/img/logos.png";
import logo2 from "../../assets/img/logo-senepave3.png";
import { useSelector, useDispatch } from "react-redux";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const devisData = useSelector((state) => state.devisStore.devis);
  // console.log("DEBIS", devisData.devis);
  const location = useLocation();
  const dispatch = useDispatch();
  return (
    <Disclosure as="nav" className="bg-gray-700 p-1">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <img
                    className="m-2 block lg:hidden h-10 w-auto"
                    src={logo}
                    alt="Workflow"
                  />
                  <img
                    className="hidden lg:block h-10 w-auto"
                    src={logo2}
                    alt="Workflow"
                  />
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    {navigationStatic.map((item) => (
                      <Link
                        key={item.name}
                        to={item.to}
                        className={classNames(
                          item.href === location.pathname
                            ? "bg-gray-900 text-white "
                            : "text-gray-300 hover:bg-gray-700 hover:text-white ",
                          "px-3 py-2 rounded-md font-medium "
                        )}
                        aria-current={
                          item.href === location.pathname ? "page" : undefined
                        }
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>

              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <div className="m-1 flow-root lg:ml-8">
                  <Link
                    to="/shoppingCart"
                    className="group -m-2 p-2 flex items-center"
                  >
                    <ShoppingBagIcon
                      // className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-50 "
                      className={classNames(
                        devisData === 0
                          ? " ml-2 text-sm font-medium text-gray-400 group-hover:text-gray-50"
                          : " text-gray-300  hover:bg-gray-700 hover:text-white ",
                        "h-6 w-6 "
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        devisData === 0
                          ? " ml-2 text-sm font-medium text-gray-400 group-hover:text-gray-50"
                          : " text-gray-300  hover:bg-gray-700 hover:text-white "
                      )}
                    >
                      {devisData.length}
                    </span>
                    <span className="sr-only">items in cart, view bag</span>
                  </Link>
                </div>
                {/* <button
                  type="button"
                  className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                {/* Profile dropdown */}
                {/* <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src="https://scontent.fymy1-1.fna.fbcdn.net/v/t39.30808-6/267751894_10227940327396546_2964255290512834427_n.jpg?_nc_cat=108&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=BiKAcB0bz6sAX9mT6jP&tn=FMywBVwsz3yyAGpO&_nc_ht=scontent.fymy1-1.fna&oh=00_AT82Pt-wlG7B1l_AVA-OGOOVGQq0WGA7fR9tUCeEhQLh2Q&oe=61DCBF3F"
                        alt="ibrahima"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="#"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Profil
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="#"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Réglages
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="#"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 "
                            )}
                          >
                            Déconnexion
                          </Link>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu> */}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 ">
              {navigationStatic.map((item) => (
                <a
                  key={item.name}
                  as="a"
                  href={item.to}
                  className={classNames(
                    item.href === location.pathname
                      ? " bg-gray-900 text-white "
                      : " text-gray-300 hover:bg-gray-700 hover:text-white ",
                    "block px-3 py-2 rounded-md text-base font-medium "
                  )}
                  aria-current={
                    item.href === location.pathname ? "page" : undefined
                  }
                >
                  {item.name}
                </a>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
