/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useState, useEffect } from "react";
import { products } from "../../utils/constants/navigations";
import {
  CheckIcon,
  ClockIcon,
  QuestionMarkCircleIcon,
  XIcon as XIconSolid,
} from "@heroicons/react/solid";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  addDevis,
  deleteDevis,
  updateQuantite,
} from "../../redux/store/devisStore";
import { RewindIcon } from "@heroicons/react/outline";
import formatMoney from "../../utils/FormatMoney";

const ShoppingCart = () => {
  const devisData = useSelector((state) => state.devisStore.devis);
  console.log("devis", devisData);
  const dispatch = useDispatch();
  const [montantTotal, setMontantTotal] = useState(0);
  console.log("Chopping Devis", devisData);
  const sousTotal = (data) => {
    let sousTotal = 0;
    data.map((el) => {
      sousTotal += el.quantite * el.prixVente;
    });
    setMontantTotal(sousTotal);
  };
  useEffect(() => {
    sousTotal(devisData);
  }, [devisData]);

  let history = useHistory();
  // console.log(products);
  return (
    <div className="">
      <main className="max-w-2xl mx-auto pt-16 pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Mon Panier
        </h1>

        <form className="mt-12 lg:grid lg:grid-cols-12 lg:gap-x-12 lg:items-start xl:gap-x-16">
          <section aria-labelledby="cart-heading" className="lg:col-span-7">
            <h2 id="cart-heading" className="sr-only">
              Items in your shopping cart
            </h2>

            <ul
              role="list"
              className="border-t border-b border-gray-200 divide-y divide-gray-200"
            >
              {devisData.map((product, productIdx) => (
                <li key={productIdx} className="flex py-6 sm:py-10">
                  <div className="flex-shrink-0">
                    <img
                      src={product.images[0].src}
                      className="w-24 h-24 rounded-md object-center object-cover sm:w-48 sm:h-48"
                    />
                  </div>

                  <div className="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                    <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                      <div>
                        <div className="flex justify-between">
                          <h3 className="text-sm">
                            <a
                              href={product.href}
                              className="font-medium text-gray-700 hover:text-gray-800"
                            >
                              {product.name}
                            </a>
                          </h3>
                        </div>
                        {product.couleur ? (
                          <div className="mt-1 flex text-sm">
                            <p className="text-gray-500">Couleur</p>

                            <p className="ml-7 pl-4 border-l border-gray-200 text-gray-500">
                              {product.couleur}
                            </p>
                          </div>
                        ) : null}
                        {product.taille ? (
                          <div className="mt-1 flex text-sm">
                            <p className="text-gray-500">Taille</p>
                            <p className="ml-11 pl-4 border-l border-gray-200 text-gray-500 ">
                              {product.taille}
                            </p>
                          </div>
                        ) : null}
                        {product.epaisseur ? (
                          <div className="mt-1 flex text-sm">
                            <p className="text-gray-500">Epaisseur</p>
                            <p className="ml-4 pl-4 border-l border-gray-200 text-gray-500">
                              {product.epaisseur}
                            </p>
                          </div>
                        ) : null}
                        <div className="mt-5 flex text-sm">
                          <p className="text-gray-500">Prix</p>
                          <p className="ml-12 pl-4 border-l border-gray-200 text-gray-500">
                            {formatMoney(product.prixVente)} €
                          </p>
                        </div>
                        <div className="mt-5 flex text-sm">
                          <p className="text-gray-500">Sous Total</p>
                          <p className="ml-2 pl-4 border-l border-gray-200 text-gray-500">
                            {formatMoney(product.prixVente * product.quantite)}{" "}
                            €
                          </p>
                        </div>
                      </div>

                      <div className="mt-4 sm:mt-0 sm:pr-9">
                        <label
                          htmlFor={`quantity-${productIdx}`}
                          className="sr-only"
                        >
                          Quantite, {product.name}
                        </label>

                        <input
                          type="number"
                          value={product.quantite}
                          style={{ width: 150 }}
                          className="rounded-md border border-gray-300 py-1.5 text-base leading-5 font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          onChange={(e) => {
                            dispatch(
                              updateQuantite({
                                id: product.id,
                                quantite: e.target.value,
                              })
                            );
                          }}
                        />

                        <div className="absolute top-0 right-0">
                          <button
                            onClick={() => dispatch(deleteDevis(product.id))}
                            type="button"
                            className="-m-2 p-2 inline-flex text-red-400 hover:text-gray-500"
                          >
                            <span className="sr-only">Remove</span>
                            <XIconSolid
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    </div>

                    {product.inStock === undefined ? (
                      <p className="mt-4 flex text-sm text-gray-700 space-x-2">
                        <ClockIcon
                          className="flex-shrink-0 h-5 w-5 text-gray-300"
                          aria-hidden="true"
                        />

                        <span className="text-gray-400">
                          {product.inStock
                            ? "In stock"
                            : `En cours de Ré-approvisionnement`}
                        </span>
                      </p>
                    ) : (
                      <>
                        <CheckIcon
                          className="flex-shrink-0 h-5 w-5 text-green-500"
                          aria-hidden="true"
                        />
                        <span>
                          {product.inStock
                            ? "In stock"
                            : `Livraison Estimé :  in ${product.leadTime}`}
                        </span>
                      </>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </section>

          {/* Order summary */}
          <section
            aria-labelledby="summary-heading"
            className="mt-16 bg-gray-50 rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5"
          >
            <h2
              id="summary-heading"
              className="text-lg font-medium text-gray-900"
            >
              Detail Commande
            </h2>

            <dl className="mt-6 space-y-4">
              <div className="flex items-center justify-between">
                <dt className="text-sm text-gray-600">Montant HT</dt>
                <dd className="text-sm font-medium text-gray-900">
                  {formatMoney(montantTotal)} €
                </dd>
              </div>
              <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                <dt className="flex items-center text-sm text-gray-600">
                  <span>Estimation frais de livraison </span>
                  <a
                    href="#"
                    className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">
                      Learn more about how shipping is calculated
                    </span>
                    <QuestionMarkCircleIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </a>
                </dt>
                <dd className="text-sm font-medium text-gray-900">-----</dd>
              </div>
              <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                <dt className="flex text-sm text-gray-600">
                  <span>Taxe </span>
                  <a
                    href="#"
                    className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">
                      Learn more about how tax is calculated
                    </span>
                    <QuestionMarkCircleIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </a>
                </dt>
                <dd className="text-sm font-medium text-gray-900">
                  {formatMoney((montantTotal * 20) / 100)} €
                </dd>
              </div>
              <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                <dt className="text-base font-medium text-gray-900">
                  Montant Total
                </dt>
                <dd className="text-base font-medium text-gray-900">
                  {formatMoney(montantTotal + (montantTotal * 20) / 100)} €
                </dd>
              </div>
            </dl>

            <div className="mt-6 flex space-x-2">
              <Link
                className="w-1/2 bg-orange-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-center font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                type="button"
                to={"/produits"}
              >
                <span>
                  {" "}
                  <RewindIcon className="h-6 absolute  " />{" "}
                </span>{" "}
                Ajout Article
              </Link>
              <button
                // type="submit"
                className="w-1/2 bg-indigo-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                onClick={() => {
                  history.push("/paiement");
                }}
                disabled={devisData.length <= 0 ? true : false}
              >
                Je réserve !
              </button>
            </div>
          </section>
        </form>

        {/* Related products */}
        <section aria-labelledby="related-heading" className="mt-24">
          <h2
            id="related-heading"
            className="text-lg font-medium text-gray-900"
          >
            {/* You may also like&hellip; */}
          </h2>

          <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            {/* {relatedProducts.map((relatedProduct) => (
              <div key={relatedProduct.id} className="group relative">
                <div className="w-full min-h-80 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                  <img
                    src={relatedProduct.imageSrc}
                    alt={relatedProduct.imageAlt}
                    className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                  />
                </div>
                <div className="mt-4 flex justify-between">
                  <div>
                    <h3 className="text-sm text-gray-700">
                      <a href={relatedProduct.href}>
                        <span aria-hidden="true" className="absolute inset-0" />
                        {relatedProduct.name}
                      </a>
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {relatedProduct.color}
                    </p>
                  </div>
                  <p className="text-sm font-medium text-gray-900">
                    {relatedProduct.price}
                  </p>
                </div>
              </div>
            ))} */}
          </div>
        </section>
      </main>
    </div>
  );
};

export default ShoppingCart;
