import React, { useEffect, useState, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, ExclamationIcon } from "@heroicons/react/outline";
import { deleteCommande, getCommandeInfo } from "../../api/commandeApi";
import Loader from "../../components/shared/Loader";
import moment from "moment";
import { addFacture } from "../../api/factureApi";
const selectData = [
  { id: 1, label: "Non payé" },
  { id: 2, label: "Payé" },
  { id: 3, label: "Annuler" },
  { id: 4, label: "En cours" },
];
export default function AddFacture() {
  const { id } = useParams();
  const [commande, setCommande] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [redirectId, setRedirectId] = useState(0);
  const [infoUser, setInfoUser] = useState({
    nom: "",
    email: "",
    tel: 0,
    adresse: "",
    statut: "statut 1",
    tva: 20,
    dateFin: null,
  });
  const getCommande = async () => {
    const data = await getCommandeInfo(id);
    setCommande(data.data);
    console.log("DATA", data.data);
    setInfoUser({
      id: data.data.idClient,
      nom: data.data.nomClient,
      email: data.data.email,
      tel: data.data.phone,
      adresse: data.data.adresse,
      statut: selectData[0].label,
      tva: 20,
      dateFin: moment(new moment().add(1, "M")).format("DD-MM-YYYY"),
    });
    setLoading(false);
  };
  useEffect(() => {
    getCommande();
  }, []);
  function getSumHt(array, column) {
    let values = array.map((item) => parseInt(item[column]) || 0);
    return values.reduce((a, b) => a + b);
  }
  function getSumTtc(array, column) {
    let values = array.map((item) => parseInt(item[column]) || 0);
    let pht = values.reduce((a, b) => a + b);
    let t = (pht * infoUser.tva) / 100;
    return pht + t;
  }
  function printCertificate() {
    const printContents = document.getElementById("certificate").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
  const handleUserInfoChange = (e) => {
    e.preventDefault();
    setInfoUser({ ...infoUser, [e.target.name]: e.target.value });
  };
  const onSubmit = async () => {
    setLoading(true);
    const facture = {
      idClient: infoUser.id,
      idDevis: commande._id,
      nomClient: infoUser.nom,
      email: infoUser.email,
      adresse: infoUser.adresse,
      dateFin: infoUser.dateFin,
      phone: infoUser.tel,
      commandes: commande.commandes,
      statut: infoUser.statut,
      tva: infoUser.tva,
      montantHt: getSumHt(commande.commandes, "sousTotal"),
      montantTtc: getSumTtc(commande.commandes, "sousTotal"),
    };
    try {
      const data = await addFacture(facture);
      setRedirectId(data.data.facture._id);
      setOpen(true);
      setLoading(false);
    } catch (error) {}
  };
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Creation de la facture
                </h3>
                <div style={{ width: "100%" }}>
                  <section
                    id="certificate"
                    aria-labelledby="applicant-information-title"
                  >
                    <div className="bg-white shadow sm:rounded-lg ">
                      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="">
                          <label
                            htmlFor=""
                            className="uppercase block text-sm font-medium text-gray-700"
                          >
                            Information du client
                          </label>
                          <div className="mt-5 sm:grid grid-cols-4 gap-4">
                            <div>
                              <label
                                htmlFor="nom"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Nom complet
                              </label>
                              <p> {infoUser.nom}</p>
                            </div>
                            <div>
                              <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Email
                              </label>
                              <p>{infoUser.email}</p>
                            </div>
                            <div>
                              <label
                                htmlFor="tel"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Tel
                              </label>
                              <p>{infoUser.tel}</p>
                            </div>
                            <div>
                              <label
                                htmlFor="adresse"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Adresse
                              </label>
                              <p>{infoUser.adresse}</p>
                            </div>
                          </div>
                        </div>
                        <div className="mt-5 w-full border-t border-gray-400"></div>
                        <div>
                          <label
                            htmlFor=""
                            className="uppercase block text-sm font-medium text-gray-700"
                          >
                            Information de Facturation
                          </label>
                          <div className="mt-3 lg:grid lg:grid-cols-4 gap-4">
                            <div>
                              <label
                                htmlFor="nom"
                                className="block text-sm font-medium text-gray-700"
                              >
                                TVA
                              </label>
                              <input
                                type="number"
                                name="tva"
                                id="tva"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                value={infoUser.tva}
                                onChange={handleUserInfoChange}
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="dateFin"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Fin d'echeance le {infoUser.dateFin}
                              </label>
                              <input
                                type="date"
                                name="dateFin"
                                id="dateFin"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                value={infoUser.dateFin}
                                onChange={handleUserInfoChange}
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="dateFin"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Statut
                              </label>
                              <select
                                value={infoUser.statut}
                                onChange={handleUserInfoChange}
                                id="country"
                                name="statut"
                                autoComplete="country-name"
                                className="max-w-lg mt-1 block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                              >
                                {selectData.map((el) => {
                                  return (
                                    <option value={el.label} key={el.id}>
                                      {el.label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                          <div
                            style={{ width: "100%" }}
                            className="sm:col-span-2"
                          >
                            <dt
                              style={{ width: "100%" }}
                              className="text-sm font-medium text-gray-500"
                            ></dt>
                            <dd
                              style={{ width: "100%" }}
                              className="mt-1 text-sm text-gray-900"
                            ></dd>
                          </div>
                          <div className="sm:col-span-2">
                            <dd className="mt-1 text-sm text-gray-900">
                              <table className="mt-5 min-w-full divide-y divide-gray-500">
                                <thead className="bg-gray-900 text-white">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                                    >
                                      Nom du pave
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                                    >
                                      Detail
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                                    >
                                      Prix unitaire
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                                    >
                                      Quantité
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                                    >
                                      Total
                                    </th>
                                    <th
                                      scope="col"
                                      className="relative px-6 py-3"
                                    >
                                      <span className="sr-only">Edit</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                  {commande.commandes.map((commande, i) => (
                                    <tr
                                      key={i}
                                      className={
                                        i % 2 === 0 ? "bg-white" : "bg-gray-50"
                                      }
                                    >
                                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                        {commande.produitName}
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {commande.detail
                                          .split("; ")
                                          .map((d, index) => (
                                            <p key={index}> {d} </p>
                                          ))}
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {commande.prix} €
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {commande.quantite} {commande.unite}
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {commande.sousTotal} €
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"></td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </dd>
                          </div>
                        </dl>
                      </div>
                      <div
                        className="bg-gray-200"
                        style={{ width: "100%", height: 2 }}
                      ></div>
                      <div className="divide-y flex justify-end">
                        <dl className="mt-2 mr-5  border-t border-b border-gray-200 divide-y divide-gray-200">
                          <div className=" py-1 flex  text-sm font-medium">
                            <dt className="mr-2 text-gray-500 text-white">
                              Montant HT: {"  "}
                            </dt>
                            <dd className="text-gray-900">
                              {" "}
                              {getSumHt(commande.commandes, "sousTotal")} €
                            </dd>
                          </div>
                          <div className=" py-1 flex  text-sm font-medium">
                            <dt className="mr-2 text-gray-500">Tva: </dt>
                            <dd className="text-gray-900"> {infoUser.tva}%</dd>
                          </div>
                          <div className=" py-1 flex  text-sm font-medium">
                            <dt className="mr-2 text-gray-500">Montant TTC:</dt>
                            <dd className="text-gray-900">
                              {" "}
                              {getSumTtc(commande.commandes, "sousTotal")} €
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="pt-5">
                  <div className="flex justify-start">
                    <Link
                      to={{
                        pathname: `/dashboard/commande/${commande._id}`,
                        state: { from: false },
                      }}
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Annuler
                    </Link>
                    <button
                      onClick={onSubmit}
                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Enregistrer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Succès
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Facture créee avec succès
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <Link
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    to={`/dashboard/factures/${redirectId}`}
                  >
                    ok
                  </Link>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
