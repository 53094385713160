import React from "react";
import BrandText from "../../components/About/BrandText";
import FormAbout from "../../components/About/FormAbout";
import HeaderAbout from "../../components/About/HeaderAbout";

const About = () => {
  return (
    <div>
      <HeaderAbout />
      <BrandText />
      <FormAbout />
    </div>
  );
};

export default About;
