import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ExclamationIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import Loader from "../../components/shared/Loader";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  addDocument,
  getAllDocument,
  deleteDocument,
} from "../../api/documentApi";

import { getAllClient } from "../../api/clientApi";
import { sendDocument } from "../../api/statistiqueApi";
import { useDispatch, useSelector } from "react-redux";
import { currentToken } from "../../redux/store/authStore";
import axios from "axios";
import "../../axios.js";

export default function Document() {
  const tokenData = useSelector(currentToken);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [open2, setOpen2] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [email, setEmail] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [infoUser, setInfoUser] = useState({});
  const [clientData, setClientData] = useState([]);
  const [files, setFiles] = useState([]);
  const [sendForm, setSendForm] = useState([]);
  const [form, setForm] = useState({
    label: "",
    file: "",
  });

  function updateField(item, value) {
    setForm({
      ...form,
      [item]: value,
    });
  }

  const getClient = async () => {
    setLoading(true);
    let temp = [];
    const data = await getAllClient();
    data.data.clients.forEach((client) => {
      temp.push({
        value: client,
        label: `${client.name} ( ${client.email} )`,
      });
    });
    setClientData(temp);

    if (tokenData) {
      axios.defaults.headers["x-access-token"] = tokenData;
    }
    const files = await axios.get("/document/");
    // const files = await getAllDocument();

    console.log("Files", files);
    setFiles(files.data.documents);
    setLoading(false);
  };

  const onChangeAttachment = (e, file) => {
    let tmp = sendForm;
    if (e.target.checked) {
      let t = {
        id: file._id,
        label: file.label,
        file: file.url,
      };
      tmp.push(t);
    } else {
      let t = tmp.filter((t) => t.id !== file._id);
      tmp = t;
    }
    setSendForm(tmp);
  };

  const onChangeEmail = (e) => {
    if (e.__isNew__) {
      setEmail(e.value);
    } else {
      setEmail(e.value.email);
    }
  };

  const sendFile = async () => {
    if (sendForm.length !== 0 && email !== "") {
      setLoading(true);
      await sendDocument({ files: sendForm, email: email });
      setSendForm([]);
      setEmail("");
      setLoading(false);
      setOpen1(true);
    } else {
    }
  };

  const onSubmit = async () => {
    let formData = new FormData();
    formData.append("label", form.label);
    formData.append("file", form.file);
    setOpen(false);
    setLoading(true);
    try {
      await addDocument(formData);
    } catch (error) {
      console.log("error", error);
    }
    getClient();
  };

  const onDelete = async () => {
    setLoading(true);
    await deleteDocument(deleteId);
    getClient();
  };
  useEffect(() => {
    getClient();
  }, []);

  // console.log("tokenData", tokenData);

  return (
    <>
      <div>
        {loading ? (
          <Loader />
        ) : (
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <h1 className="mb-2 text-2xl font-semibold text-gray-900">
                Documents de l'entreprise
              </h1>
              <button
                onClick={() => setOpen(true)}
                type="button"
                className=" mb-3 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Ajouter un Fichier Pdf
              </button>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-1 md:gap-6">
                  <div className="mt-5 md:mt-0 md:col-span-2">
                    <fieldset className="mb-5 lg:ml-64  lg:mr-64 justify-center">
                      <legend className="text-lg font-medium text-gray-900 underline-offset-1">
                        Liste des fichiers
                      </legend>
                      {files.length == 0 ? (
                        <p className="text-center text-green-800">
                          Pas de fichier a envoyer
                        </p>
                      ) : (
                        <div className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                          {files.map((file, fileIdx) => (
                            <div>
                              <div
                                key={fileIdx}
                                className="relative flex items-start py-4 mx-auto justify-center"
                              >
                                <div className="ml-3  flex items-center h-5 center">
                                  <input
                                    id={`file-${file.id}`}
                                    name={`person-${file.id}`}
                                    type="checkbox"
                                    value={file}
                                    className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                    onChange={(e) =>
                                      onChangeAttachment(e, file)
                                    }
                                  />
                                </div>
                                <div className="min-w-0 flex-1 text-sm ml-5">
                                  <label
                                    htmlFor={`file-${file.id}`}
                                    className="font-medium text-gray-700 select-none"
                                  >
                                    {file.label}
                                  </label>
                                </div>

                                <button
                                  type="button"
                                  className="inline-flex items-center p-1.5 border border-red rounded-full shadow-sm text-white bg-red-600 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                  onClick={() => {
                                    setDeleteId(file._id);
                                    setOpen2(true);
                                  }}
                                >
                                  <TrashIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </fieldset>
                    <div className="m-5">
                      <div>
                        <label
                          htmlFor="tel"
                          className="mt-4 mb-2 block text-sm font-medium text-gray-700 italic"
                        >
                          Séléctionner le client ou saisir l'adresse Mail
                        </label>
                        <CreatableSelect
                          onChange={onChangeEmail}
                          options={clientData}
                        />
                      </div>
                      <div className="pt-5">
                        <div className="flex justify-start">
                          <button
                            onClick={sendFile}
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                          >
                            Envoyer
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /End replace */}
            </div>
          </div>
        )}
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={setOpen}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className="mt-3 sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg text-center leading-6 font-medium text-gray-900"
                      >
                        Ajouter un document
                      </Dialog.Title>
                      <div className="mt-5 grid grid-cols-1 gap-4">
                        <div className="">
                          <label
                            htmlFor="first-name"
                            className="text-left block text-sm font-medium text-gray-700"
                          >
                            Nom du document
                          </label>
                          <div className="mt-1">
                            <input
                              id="nom"
                              name="name"
                              type="text"
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                              value={form.name}
                              onChange={(e) => {
                                updateField("label", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block">
                            <span className="sr-only">
                              Choisir le Document(.pdf)
                            </span>
                            <input
                              onChange={(e) =>
                                updateField("file", e.target.files[0])
                              }
                              type="file"
                              className="block w-full text-sm text-slate-500
                                          file:mr-4 file:py-2 file:px-4
                                          file:rounded-full file:border-0
                                          file:text-sm file:font-semibold
                                          file:bg-violet-50 file:text-violet-700
                                          hover:file:bg-violet-100
                                        "
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-hovers focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                      onClick={() => onSubmit()}
                    >
                      Envoyé
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={() => setOpen(false)}
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <Transition.Root show={open1} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={setOpen1}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Succès
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Bravo document envoyé avec Succès
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      onClick={() => setOpen1(false)}
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    >
                      ok
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <Transition.Root show={open2} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={setOpen2}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Suppression
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Voulez-vous supprimer ce document ?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => {
                        setOpen2(false);
                        onDelete();
                      }}
                    >
                      Supprimer
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpen2(false)}
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
}
