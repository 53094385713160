import axios from "axios";
// import { useSelector } from "react-redux";
// import { currentToken } from "./redux/store/authStore";

// let tokenData = null;
// function Test() {
//   tokenData = useSelector(currentToken);
//   console.log("tokenData", tokenData);
//   return tokenData;
//   // console.log("tokenData", tokenData);
// }

// Test();

axios.defaults.baseURL = `${process.env.REACT_APP_API}/api`;
axios.defaults.withCredentials = true;

// const token = localStorage.getItem("token");

if ("tokenData") {
  axios.defaults.headers["x-access-token"] = "tokenData";
}
axios.defaults.headers["Content-type"] = "application/json";
