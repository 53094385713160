import axios from "axios";
import "../axios";

export const addMateriel = async (form) => {
  let data = await axios.post("/materiels/", form);
  return data;
};

export const getAllMateriel = async (form) => {
  let data = await axios.get("/materiels/");
  return data;
};

export const getMaterielInfo = async (id) => {
  let data = await axios.get(`/materiels/${id}`);
  return data;
};

export const deleteMateriel = async (id) => {
  let data = await axios.delete(`/materiels/${id}`);
  return data;
};
export const updateMateriel = async (id, form) => {
  let data = await axios.patch(`/materiels/${id}`, form);
  return data;
};
