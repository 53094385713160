import React from "react";
import { Link } from "react-router-dom";

const projets = [
  {
    id: 1,
    titre: "Pave 1",
    src: "https://www.pave-uni-rive-sud.com/uploads/2/8/1/7/28176059/pose-et-installation-pave-uni_orig.jpg",
    description: ` Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical
            gentrify, subway tile poke farm-to-table. Franzen you probably
            haven't heard of them man bun deep jianbing selfies heirloom.`,
    lieux: "Chambéry",
  },
  {
    id: 2,
    titre: "Pave 2",
    src: "https://terrassementjonic.com/wp-content/uploads/entree-muret-pave-uni-moderne-opt.jpg",
    description: ` Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical
            gentrify, subway tile poke farm-to-table. Franzen you probably
            haven't heard of them man bun deep jianbing selfies heirloom.`,
    lieux: "Geneve",
  },
  {
    id: 3,
    titre: "Pave 3",
    src: "https://www.aravis-enrobage.com/wp-content/uploads/2019/05/Paves-de-paris-melanges-brut-Sillingy-min.jpg",
    description: ` Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical
            gentrify, subway tile poke farm-to-table. Franzen you probably
            haven't heard of them man bun deep jianbing selfies heirloom.`,
    lieux: "Monaco",
  },
  {
    id: 4,
    titre: "Pave 4",
    src: "https://www.gtlpaysagiste.ca/app/uploads/2019/08/8-e1598364265234.png",
    description: ` Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical
            gentrify, subway tile poke farm-to-table. Franzen you probably
            haven't heard of them man bun deep jianbing selfies heirloom.`,
    lieux: "Nice",
  },
  {
    id: 5,
    titre: "Pave 5",
    src: "https://www.bretaudeau-paysagiste.fr/img/galerie/pavage/pavage_granit_pente.jpg",
    description: ` Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical
            gentrify, subway tile poke farm-to-table. Franzen you probably
            haven't heard of them man bun deep jianbing selfies heirloom.`,
    lieux: "Paris",
  },
  {
    id: 6,
    titre: "Pave 6",
    src: "https://files.fabemi.fr/2020/03/pave-proven%C3%A7al-gris-nuance-3.jpg",
    description: ` Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical
            gentrify, subway tile poke farm-to-table. Franzen you probably
            haven't heard of them man bun deep jianbing selfies heirloom.`,
    lieux: "Lille",
  },
  {
    id: 7,
    titre: "Pave 7",
    src: "https://amenagementdenis.com/wp-content/uploads/2020/02/la-sobriete-des-lignes-laisse-toute-la-place-a-l-eclat-des-fleurs-Am-2016-24_03_0.png",
    description: ` Chantier grenoble dazdazddad`,
    lieux: "Grenoble",
  },
];

const ProjectPage = () => {
  const showdetail = () => {
    console.log("super");
  };
  return (
    <div className=" mt-5 min-w-full">
      {/* Hero card */}
      <div className="relative">
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 ">
          <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
            <div className="absolute inset-0 ">
              <img
                className="h-full w-full object-cover"
                src="https://www.bolduc.ca/upload/image/2C4A1803%20(1).jpg"
                alt="People working on laptops"
              />
              <div className="absolute inset-0 bg-gray-500 mix-blend-multiply" />
            </div>
            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                <span className="block text-white">
                  " Nos plus belles Réalisations "
                </span>
                {/* <span className="block text-red-200 mt-3">
                  A Travers{" "}
                  <span className="underline text-white italic">La France</span>
                </span> */}
              </h1>
              {/* <p className="mt-6 max-w-lg mx-auto text-center text-xl text-red-200 sm:max-w-3xl">
                Nos plus belle réalisation a travers toute la France
              </p> */}
              <div className="mt-10 max-w-lg mx-auto sm:max-w-none sm:flex sm:justify-center">
                {/* <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5"> */}
                <Link
                  to="/about"
                  className="flex items-center justify-center px-4 py-3 text-base font-medium rounded-md shadow-sm shadow-white text-white bg-red-500 hover:bg-red-700 sm:px-8"
                >
                  Formulaire de Contact
                </Link>
                {/* <a
                    href="#"
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8"
                  >
                    Live demo
                  </a> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
            Master Cleanse Reliac Heirloom
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical
            gentrify, subway tile poke farm-to-table. Franzen you probably
            haven't heard of them man bun deep jianbing selfies heirloom.
          </p>
        </div>
        <div className="flex flex-wrap -m-4">
          {projets.map((projet) => (
            <div className="lg:w-1/3 sm:w-1/2 p-4 " key={projet.id}>
              <div
                className="flex relative cursor-pointer"
                onClick={() => showdetail(projet.id)}
              >
                <img
                  alt={projet.titre}
                  className="absolute inset-0 w-full h-full object-cover object-center rounded-xl"
                  src={projet.src}
                />
                <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-80 rounded-xl">
                  <h2 className="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">
                    {projet.titre}
                  </h2>
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                    {projet.titre}
                  </h1>
                  <p className="leading-relaxed h-44 ">{projet.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectPage;
