import React from "react";
import Navbar from "../components/layouts/Navbar";
import Footer from "../components/layouts/Footer";
import Home from "../pages/static/Home";
import About from "../pages/static/About";
import DetailCommande from "../pages/static/DetailCommande";
import DetailFacture from "../pages/static/DetailFacture";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Productdetail from "../pages/static/Productdetail";
import ProductPage from "../pages/static/ProductPage";
import PaiementPage from "../pages/static/PaiementPage";
import ShoppingCart from "../pages/static/ShoppingCart";
import ProjectPage from "../pages/static/ProjectPage";
import Error404 from "../pages/static/Error404";
import LoginPage from "../pages/static/LoginPage";
import ForgotPassword from "../pages/static/forgotPassword";
import ResetPassword from "../pages/static/ResetPassword";
import DetaiMateriel from "../pages/static/DetaiMateriel";

const ClientRoute = () => {
  return (
    <div>
      <Router>
        <Navbar />
        <Switch>
          <Route component={LoginPage} path="/login" />
          <Route component={PaiementPage} path="/paiement" />
          <Route component={ProjectPage} path="/projets" />
          <Route component={Productdetail} path="/produitdetail/:id" />
          <Route component={DetaiMateriel} path="/materiel/:id" />
          <Route component={ShoppingCart} path="/shoppingcart" />
          <Route component={ProductPage} path="/produits" />
          <Route component={About} path="/about" />
          <Route component={ForgotPassword} path="/forgot-password" />
          <Route component={ResetPassword} path="/reset-password/:token" />
          <Route component={DetailFacture} path="/facture/:id" />
          <Route component={DetailCommande} path="/commande/:id" />
          <Route exact component={Home} path="/" />
          <Route component={LoginPage} path="*" />
          {/* <Route component={Error404} path="*" /> */}
        </Switch>
        <Footer />
      </Router>
    </div>
  );
};

export default ClientRoute;
