/* eslint-disable jsx-a11y/alt-text */
import React, { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, ExclamationIcon } from "@heroicons/react/outline";
import Loader from "../../components/shared/Loader";
// import Select from "react-select";
// import makeAnimated from "react-select/animated";
import { Link, useParams } from "react-router-dom";
import { getAllPave } from "../../api/paveApi";
import { getAllMateriel } from "../../api/materielApi";
import {
  addCommande,
  getCommandeInfo,
  updateCommade,
} from "../../api/commandeApi";
import formatMoney from "../../utils/FormatMoney";

export default function UpdateCommande() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [form, setForm] = useState(null);
  const [commandes, setCommandes] = useState([]);
  const [paves, setPaves] = useState([]);
  const [materiels, setMateriels] = useState([]);
  const [tailles, setTailles] = useState([]);
  const [couleurs, setCouleurs] = useState([]);
  const [epaisseurs, setEpaisseur] = useState([]);
  const [fmat, setFMat] = useState({
    quantite: 0,
    nom: null,
  });
  const [fdep, setDep] = useState({
    quantite: 0,
    nom: "",
    desc: "",
    prix: 0,
  });
  const [option, setOption] = useState(null);
  const [infoUser, setInfoUser] = useState({
    nom: "",
    email: "",
    tel: 0,
    adresse: "",
  });
  const [values, setValues] = useState({
    produitName: "",
    quantite: 0,
    prix: 0,
    unite: "",
    nom: "",
  });
  useEffect(() => {
    getData();
    let getDetailCommande = async () => {
      const data = await getCommandeInfo(id);
      console.log("DATA", data.data);
      setInfoUser({
        nom: data.data.nomClient,
        email: data.data.email,
        adresse: data.data.adresse,
        tel: data.data.phone,
      });
      setCommandes(data.data.commandes);
    };
    getDetailCommande();
  }, []);
  const handleMChange = (e) => {
    e.preventDefault();
    setFMat({ ...fmat, [e.target.name]: e.target.value });
  };
  const handleDChange = (e) => {
    e.preventDefault();
    setDep({ ...fdep, [e.target.name]: e.target.value });
  };
  const handleChange = (e) => {
    e.preventDefault();
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleUserInfoChange = (e) => {
    e.preventDefault();
    setInfoUser({ ...infoUser, [e.target.name]: e.target.value });
  };
  const addProduct = (e) => {
    setTailles([]);
    e.preventDefault();
    if (values.nom && values.quantite !== 0) {
      let t = {
        produitName: option.nomPave,
        quantite: values.quantite,
        prix: option.prixVente,
        unite: "",
        detail: `epaisseur: ${values.epaisseur}; taille: ${values.taille}; couleur: ${values.couleur} `,
        sousTotal: Number(values.quantite * option.prixVente).toFixed(3),
      };
      setCommandes((commandes) => [...commandes, t]);
      setForm(null);
    }
  };
  const addMateriel = (e) => {
    // setTailles([]);
    e.preventDefault();
    let materiel = materiels[fmat.nom];
    if (materiel && fmat.quantite !== 0) {
      let t = {
        produitName: materiel.name,
        quantite: fmat.quantite,
        prix: materiel.prixUnitaire,
        unite: materiel.unite,
        detail: "",
        sousTotal: Number(fmat.quantite * materiel.prixUnitaire).toFixed(3),
      };
      setCommandes((commandes) => [...commandes, t]);
      setForm(null);
    }
  };
  const addDepense = (e) => {
    e.preventDefault();
    if (fdep.quantite !== 0) {
      let t = {
        produitName: fdep.nom,
        quantite: fdep.quantite,
        prix: fdep.prix,
        unite: "",
        detail: fdep.desc,
        sousTotal: Number(fdep.quantite * fdep.prix).toFixed(3),
      };
      setCommandes((commandes) => [...commandes, t]);
      setDep({
        quantite: 0,
        nom: "",
        desc: "",
        prix: 0,
      });
      setForm(null);
    }
  };
  const getData = async () => {
    const pdata = await getAllPave();
    const mdata = await getAllMateriel();
    setPaves(pdata.data);
    setMateriels(mdata.data.materiels);
  };
  const Total = () => {
    let total = 0;
    commandes.forEach((el) => (total += el.prix * el.quantite));
    return total;
  };
  const selectPrduct = (pave) => {
    setOption(pave);
    setEpaisseur(pave.epaisseurs);
    setTailles(pave.tailles);
    setCouleurs(pave.couleurs);
    setValues({ ...values, ["nom"]: pave.nomPave });
    setOpen2(false);
    setForm("pave");
  };
  const onSubmit = async () => {
    setLoading(true);
    const devis = {
      nomClient: infoUser.nom,
      email: infoUser.email,
      adresse: infoUser.adresse,
      phone: infoUser.tel,
      commandes: commandes,
    };
    updateCommade(id, devis).then(() => {
      setLoading(false);
      setOpen(true);
    });
  };
  return (
    <>
      <div>
        {loading ? (
          <Loader />
        ) : (
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <h1 className="mb-5 text-2xl font-semibold text-gray-900">
                Ajouter une Commande
              </h1>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-2 md:gap-6">
                  <div className="mt-5 md:mt-0 md:col-span-2">
                    <form action="#" method="POST">
                      <label
                        htmlFor=""
                        className="uppercase block text-sm font-medium text-gray-700"
                      >
                        Information du client
                      </label>
                      <div className="mt-5 grid grid-cols-4 gap-4">
                        <div>
                          <label
                            htmlFor="nom"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Nom complet
                          </label>
                          <input
                            type="text"
                            name="nom"
                            id="nom"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            value={infoUser.nom}
                            onChange={handleUserInfoChange}
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email
                          </label>
                          <input
                            type="text"
                            name="email"
                            id="email"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            value={infoUser.email}
                            onChange={handleUserInfoChange}
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="tel"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Tel
                          </label>
                          <input
                            type="number"
                            name="tel"
                            id="tel"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            value={infoUser.tel}
                            onChange={handleUserInfoChange}
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="adresse"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Adresse
                          </label>
                          <input
                            type="text"
                            name="adresse"
                            id="adresse"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            value={infoUser.adresse}
                            onChange={handleUserInfoChange}
                          />
                        </div>
                      </div>
                      <div className="mt-5 w-full border-t border-gray-400"></div>
                      <label
                        htmlFor=""
                        className="mt-5 uppercase block text-sm font-medium text-gray-700"
                      >
                        Information sur la commande
                      </label>
                      <div className="">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setOpen2(true);
                            setForm("pave");
                          }}
                          className="mt-3 ml-2 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Ajouter un pave
                        </button>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setForm("materiel");
                          }}
                          className="mt-3 ml-2 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Ajouter un materiel
                        </button>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setForm("depense");
                          }}
                          className="mt-3 ml-2 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Ajouter Autre depense
                        </button>
                      </div>
                      <div>
                        {form === "pave" ? (
                          <>
                            <div className="mt-5 grid sm:grid-cols-2 lg:grid-cols-4 gap-3">
                              {paves.length === 0 ? (
                                <div></div>
                              ) : (
                                <>
                                  <div>
                                    <label
                                      htmlFor="namePace"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Nom Pave
                                    </label>
                                    <div className=" sm:mt-0 sm:col-span-2 w-full">
                                      <input
                                        readOnly={true}
                                        type="text"
                                        name="nom"
                                        id="nom"
                                        className=" bg-gray-100  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                        value={values.nom}
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <label
                                      htmlFor="tel"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Epaisseur
                                    </label>
                                    <select
                                      onChange={handleChange}
                                      id="epaisseur"
                                      name="epaisseur"
                                      autoComplete="epaisseur-name"
                                      className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                    >
                                      <option></option>
                                      {epaisseurs.map((epaisseur, i) => (
                                        <option value={epaisseur} key={i}>
                                          {epaisseur}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div>
                                    <label
                                      htmlFor="tel"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Tailles
                                    </label>
                                    <div className=" sm:mt-0 sm:col-span-2">
                                      <select
                                        onChange={handleChange}
                                        id="taille"
                                        name="taille"
                                        autoComplete="country-name"
                                        className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                      >
                                        <option></option>
                                        {tailles.map((taille, i) => (
                                          <option value={taille} key={i}>
                                            {taille}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div>
                                    <label
                                      htmlFor="tel"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Couleurs
                                    </label>
                                    <div className="sm:mt-0 sm:col-span-2">
                                      <select
                                        onChange={handleChange}
                                        id="couleur"
                                        name="couleur"
                                        autoComplete="country-name"
                                        className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                      >
                                        <option></option>
                                        {couleurs.map((couleur, i) => (
                                          <option value={couleur} key={i}>
                                            {couleur}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div>
                                    <label
                                      htmlFor="quantite"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Quantite
                                    </label>
                                    <input
                                      type="number"
                                      name="quantite"
                                      id="quantite"
                                      className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                      value={values.quantite}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div>
                                    <button
                                      onClick={addProduct}
                                      className="mt-5 mb-3 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                      Ajouter le produit
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        ) : (
                          <div></div>
                        )}
                        {form === "materiel" ? (
                          <div className="mt-5 grid sm:grid-cols-1 lg:grid-cols-3 gap-4">
                            <div>
                              <label
                                htmlFor="nom"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Nom materiel
                              </label>
                              <select
                                onChange={handleMChange}
                                id="nom"
                                name="nom"
                                autoComplete="epaisseur-name"
                                className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-1/2 shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                              >
                                <option></option>
                                {materiels.map((materiel, i) => (
                                  <option value={i} key={i}>
                                    {materiel.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Quantite
                              </label>
                              <input
                                type="text"
                                name="quantite"
                                id="quantite"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-1/2 shadow-sm sm:text-sm border-gray-300 rounded-md"
                                value={fmat.quantite}
                                onChange={handleMChange}
                              />
                            </div>
                            <div className="mt-4">
                              <button
                                onClick={addMateriel}
                                className="mt-1  inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                Ajouter le matériel
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {form === "depense" ? (
                          <div className="mt-5 grid grid-cols-4 gap-4">
                            <div>
                              <label
                                htmlFor="nom"
                                className="block text-sm font-medium text-gray-700"
                              >
                                libelle depense
                              </label>
                              <input
                                type="text"
                                name="nom"
                                id="nom"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                value={fdep.nom}
                                onChange={handleDChange}
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="quantite"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Quantite
                              </label>
                              <input
                                type="number"
                                name="quantite"
                                id="quantite"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                value={fdep.quantite}
                                onChange={handleDChange}
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="prix"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Prix unitaire
                              </label>
                              <input
                                type="number"
                                name="prix"
                                id="prix"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                value={fdep.prix}
                                onChange={handleDChange}
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="desc"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Description
                              </label>
                              <textarea
                                name="desc"
                                id="desc"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                value={fdep.desc}
                                onChange={handleDChange}
                              />
                            </div>
                            <div className="mt-4">
                              <button
                                onClick={addDepense}
                                className="mt-1  inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                Ajouter
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </form>
                    <div className="mt-5 w-full border-t border-gray-400"></div>
                    <table className="mt-5 min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-200">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Nom du pave
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Detail
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Prix unitaire
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Quantité
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Total
                          </th>
                          <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {commandes.map((commande, i) => (
                          <tr
                            key={i}
                            className={i % 2 === 0 ? "bg-white" : "bg-gray-50"}
                          >
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {commande.produitName}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {commande.detail.split("; ").map((d, index) => (
                                <p key={index}> {d} </p>
                              ))}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {commande.prix} €
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {commande.quantite} {commande.unite}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {formatMoney(Number(commande.sousTotal))} €
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              <button
                                onClick={() => {
                                  // deleteProduct(i);
                                  setCommandes([
                                    ...commandes.slice(0, i),
                                    ...commandes.slice(i + 1),
                                  ]);
                                }}
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                Sup
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {Total() === 0 ? (
                      <div></div>
                    ) : (
                      <div className="mt-2 bg-gray-200 px-4 py-4 flex items-center sm:px-6">
                        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                          <div className="truncate">
                            <div className="flex text-sm">
                              <p className="font-medium text-indigo-600 truncate">
                                Total à regler
                              </p>
                            </div>
                          </div>
                          <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                            <div className="flex overflow-hidden -space-x-1">
                              <p>{formatMoney(Number(Total()))} € </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="pt-5">
                  <div className="flex justify-end">
                    <Link
                      // to={`/dashboard/commande/${id}`}
                      to={{
                        pathname: `/dashboard/commande/${id}`,
                        state: { from: false },
                      }}
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Annuler
                    </Link>
                    <button
                      onClick={onSubmit}
                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Enregistrer
                    </button>
                  </div>
                </div>
              </div>
              {/* /End replace */}
            </div>
          </div>
        )}
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Succès
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Commande modifiée avec succès
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <Link
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    to={`/dashboard/commande/${id}`}
                  >
                    ok
                  </Link>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={open1} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen1}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Error
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        🤷‍♂️ Tous les champs doivent être remplis
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen1(false)}
                  >
                    J'ai compris
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={open2} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen2}
        >
          <div className="flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                style={{
                  width: 1000,
                }}
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6  lg:w-full "
              >
                <div className="-mx-px border-l border-gray-200 grid grid-cols-2 sm:mx-0 md:grid-cols-3 lg:grid-cols-4">
                  {paves.map((pave) => (
                    <div
                      key={pave._id}
                      className="group relative p-2 border-r border-b border-gray-200 sm:p-6"
                    >
                      <div className="rounded-lg overflow-hidden bg-gray-200 aspect-w-1 aspect-h-1 group-hover:opacity-75">
                        <img
                          src={pave.images[0].src}
                          className="w-full h-full object-center object-cover"
                        />
                      </div>
                      <div className="pt-2 text-center">
                        <h3 className="text-sm font-medium text-gray-900">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              selectPrduct(pave);
                            }}
                          >
                            <span
                              aria-hidden="true"
                              className="absolute inset-0"
                            />
                            {pave.nomPave}
                          </button>
                        </h3>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
