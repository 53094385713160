import React from "react";
export default function Loader() {
  return (
    <div className="relative sm:py-2 mt-20">
      <div className="mx-auto max-w-md px-2 sm:max-w-xl sm:px-3 lg:px-8 lg:max-w-4xl">
        <div
          className="flex flex-wrap  justify-center"
          style={{ maxWidth: 800, justifyContent: "center" }}
        >
          <div
            style={{ borderTopColor: "transparent" }}
            className="w-12 h-12 border-4 border-red-600 border-double rounded-full animate-spin"
          ></div>
        </div>
      </div>
    </div>
  );
}
