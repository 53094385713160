import React from "react";
import pave from "../../assets/img/enva44.jpg";

const BrandText = () => {
  return (
    <div>
      <div className="relative bg-gray-800 mt-2 mb-5">
        <div className="absolute inset-0">
          <img className="w-full h-full object-cover" src={pave} alt="" />
          <div
            className="absolute inset-0 bg-gray-400 mix-blend-multiply"
            aria-hidden="true"
          />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl capitalize italic">
            En avant vos Projets !
          </h1>
          <p className="mt-6 text-xl text-gray-200 max-w-3xl hover:text-white text-justify">
            Parce qu'un projet peut être angoissant, nous sommes-là ! Posez-nous
            vos questions et vos besoins nous nous ferons un plaisir de vous y
            répondre dans les plus brefs délais
          </p>
        </div>
      </div>
    </div>
  );
};

export default BrandText;
