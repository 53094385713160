import React from "react";

export default function ImageCard({ image }) {
  return (
    <div className="p-2 pl-2 pr-2 " style={{ width: "90%" }}>
      <img
        style={{ width: 200, height: 130 }}
        className="border border-gray-700"
        src={image.src}
        alt=""
      />
    </div>
  );
}
