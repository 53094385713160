import React, { useEffect, useState } from "react";
import Loader from "../../components/shared/Loader";
import { Link } from "react-router-dom";
import { getAllPave } from "../../api/paveApi";
import PaveCard from "../../components/pave/PaveCard";

export default function PavePage() {
  const [paves, setPaves] = useState([]);
  const [loading, setLoader] = useState(false);
  useEffect(() => {
    getPave();
  }, []);
  const getPave = async () => {
    setLoader(true);
    const data = await getAllPave();
    setPaves(data.data);
    setLoader(false);
  };
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">Pavés</h1>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="mt-3">
              <Link
                to="/dashboard/addpave"
                type="button"
                className="mb-3 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Ajouter un pavé
              </Link>
              {paves.length === 0 ? (
                <h2 className="text-center mt-10 mb-8 text-lg leading-6 font-medium text-gray-900">
                  Pas de pavé enregistrés
                </h2>
              ) : (
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                  <h2 className="mb-8 text-lg leading-6 font-medium text-gray-900">
                    Liste des paves
                  </h2>
                  <ul
                    role="list"
                    className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
                  >
                    {paves.map((pave) => (
                      <PaveCard key={pave._id} pave={pave} />
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
