import React from "react";
import AdminRoute from "./routes/AdminRoute";
import ClientRoute from "./routes/ClientRoute";
import { isAuthenticated } from "./redux/store/authStore";
import { useSelector } from "react-redux";

function App() {
  const isAuth = useSelector(isAuthenticated);
  console.log("DATA", isAuth);
  return <div>{isAuth ? <AdminRoute /> : <ClientRoute />}</div>;
}

export default App;
