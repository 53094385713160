/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useState, useEffect, Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ExclamationIcon,
  RewindIcon,
} from "@heroicons/react/outline";
import Loader from "../../components/shared/Loader";
import { LockClosedIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { resetDevis } from "../../redux/store/devisStore";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { addCommande } from "../../api/commandeApi";
import "react-notifications/lib/notifications.css";
import formatMoney from "../../utils/FormatMoney";

export default function PaiementPage() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const devisData = useSelector((state) => state.devisStore.devis);
  const [montantTotal, setMontantTotal] = useState(0);
  const [formData, setFormData] = useState({
    email: "",
    nom: "",
    tel: "",
    address: "",
  });
  const dispatch = useDispatch();
  const sousTotal = (data) => {
    let sousTotal = 0;
    data.map((el) => {
      sousTotal += el.quantite * el.prixVente;
    });
    setMontantTotal(sousTotal);
  };
  useEffect(() => {
    sousTotal(devisData);
  }, [devisData]);
  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    let products = [];
    devisData.map((el) => {
      products.push({
        produitName: el.nom,
        detail: el.pave
          ? `Epaisseur: ${el.epaisseur}; Taille: ${el.taille}; Couleur: ${el.couleur} `
          : "",
        prix: el.prixVente,
        quantite: el.quantite,
        sousTotal: Number(el.quantite * el.prixVente).toFixed(3),
        unite: "",
      });
    });
    console.log("Product", products);
    let commande = {
      nomClient: formData.nom,
      email: formData.email,
      adresse: formData.address,
      phone: formData.tel,
      commandes: products,
    };
    try {
      await addCommande(commande);
      setLoading(false);
      setOpen(true);
      dispatch(resetDevis());
    } catch (error) {
      console.log("error", error);
    }
  };

  const activSendBtn =
    formData.email !== "" &&
    formData.tel !== "" &&
    formData.address !== "" &&
    formData.nom !== "" ? (
      <button
        onClick={(e) => onSubmit(e)}
        className="w-full mt-6 bg-green-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
      >
        Commander
      </button>
    ) : (
      <button
        disabled
        className="w-full cursor-not-allowed mt-6 bg-gray-400 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
      >
        Saisir vos informations
      </button>
    );

  return (
    <div className="">
      {loading ? (
        <Loader />
      ) : (
        <main className="pt-5 pb-32 min-h-full lg:overflow-hidden lg:flex lg:flex-row-reverse">
          <section
            aria-labelledby="order-heading"
            className="pt-10 bg-gray-50 px-4 py-6 sm:px-6 lg:hidden"
          >
            <Disclosure as="div" className="max-w-lg mx-auto">
              {({ open }) => (
                <>
                  <div className="flex items-center justify-between">
                    <h2
                      id="order-heading"
                      className="text-lg font-medium text-gray-900"
                    >
                      Commande
                    </h2>
                    <Disclosure.Button className="font-medium text-indigo-600 hover:text-indigo-500">
                      {open ? (
                        <span>Cacher les details</span>
                      ) : (
                        <span>Afficher les details</span>
                      )}
                    </Disclosure.Button>
                  </div>

                  <Disclosure.Panel>
                    <ul
                      role="list"
                      className="divide-y divide-gray-200 border-b border-gray-200"
                    ></ul>
                    <dl className="text-sm font-medium text-gray-500 mt-10 space-y-6">
                      <div className="flex justify-between">
                        <dt>Montant</dt>
                        <dd className="text-gray-900">
                          {formatMoney(montantTotal)} €
                        </dd>
                      </div>
                      <div className="flex justify-between">
                        <dt>Taxes</dt>
                        <dd className="text-gray-900">
                          {formatMoney((montantTotal * 20) / 100)} €
                        </dd>
                      </div>
                      <div className="flex justify-between">
                        <dt>Livraison</dt>
                        <dd className="text-gray-900">----</dd>
                      </div>
                    </dl>
                  </Disclosure.Panel>

                  <p className="flex items-center justify-between text-sm font-medium text-gray-900 border-t border-gray-200 pt-6 mt-6">
                    <span className="text-base">Montant Total </span>
                    <span className="text-base">
                      {formatMoney(montantTotal + (montantTotal * 20) / 100)} €
                    </span>
                  </p>
                </>
              )}
            </Disclosure>
          </section>

          {/* Order summary */}
          <section
            aria-labelledby="summary-heading"
            className="hidden bg-gray-50 w-full max-w-md flex-col lg:flex"
          >
            <h2 id="summary-heading" className="sr-only">
              Order summary
            </h2>

            <ul
              role="list"
              className="flex-auto overflow-y-auto divide-y divide-gray-200 px-6"
            >
              {devisData.map((product) => (
                <li key={product.id} className="flex py-6 space-x-6">
                  <img
                    src={product.images[0].src}
                    alt={product.imageAlt}
                    className="flex-none w-40 h-40 object-center object-cover bg-gray-200 rounded-md"
                  />
                  <div className="flex flex-col justify-between space-y-4">
                    <div className="text-sm font-medium space-y-1">
                      <h3 className="text-gray-900 decoration-2 uppercase">
                        {product.nom}
                      </h3>
                      <div className="border-2"></div>
                      {product.couleur ? (
                        <p className="text-gray-500">
                          <span className="text-gray-900">Couleur :</span>{" "}
                          {product.couleur}
                        </p>
                      ) : null}
                      {product.taille ? (
                        <p className="text-gray-500">
                          <span className="text-gray-900"> Taille:</span>{" "}
                          {product.taille}
                          {" cm"}
                        </p>
                      ) : null}
                      {product.epaisseur ? (
                        <p className="text-gray-900">
                          <span className="text-gray-900"> Epaisseur: </span>
                          {product.epaisseur} {" cm"}
                        </p>
                      ) : null}
                      <p className="text-gray-900">
                        {formatMoney(product.prixVente)} €
                      </p>
                    </div>
                    {/* <div className="flex space-x-4">
                      <button
                        type="button"
                        className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        Edit
                      </button>
                      <div className="flex border-l border-gray-300 pl-4">
                        <button
                          type="button"
                          className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          Remove
                        </button>
                      </div>
                    </div> */}
                  </div>
                </li>
              ))}
            </ul>

            <div className="sticky bottom-0 flex-none bg-gray-50 border-t border-gray-200 p-6">
              <dl className="text-sm font-medium text-gray-500 mt-10 space-y-6">
                <div className="flex justify-between">
                  <dt>Montant</dt>
                  <dd className="text-gray-900">
                    {formatMoney(montantTotal)} €
                  </dd>
                </div>

                <div className="flex justify-between">
                  <dt>Taxes</dt>
                  <dd className="text-gray-900">
                    {formatMoney((montantTotal * 20) / 100)} €
                  </dd>
                </div>
                <div className="flex justify-between">
                  <dt>Livraison</dt>
                  <dd className="text-gray-900">-</dd>
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 text-gray-900 pt-6">
                  <dt className="text-base">Montant Total</dt>
                  <dd className="text-base">
                    {formatMoney(montantTotal + (montantTotal * 20) / 100)} €
                  </dd>
                </div>
              </dl>
            </div>
          </section>

          {/* Checkout form */}
          <section
            aria-labelledby="payment-heading"
            className="flex-auto overflow-y-auto px-4 pb-16 sm:px-6 sm:pt-16 lg:px-8 lg:pt-0 lg:pb-24"
          >
            <div className="max-w-lg mx-auto">
              <div className="relative mt-8">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center"></div>
              </div>

              <form className="mt-6">
                <div className="grid grid-cols-12 gap-y-6 gap-x-4">
                  <div className="col-span-full">
                    <label
                      htmlFor="email-address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Nom Complet
                    </label>
                    <div className="mt-1">
                      <input
                        onChange={handleChange}
                        name="nom"
                        type="text"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="name-on-card"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        type="email"
                        id="email-address"
                        name="email"
                        onChange={handleChange}
                        autoComplete="email"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Addresse
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="address"
                        name="address"
                        onChange={handleChange}
                        autoComplete="street-address"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Téléphone
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="address"
                        name="tel"
                        onChange={handleChange}
                        autoComplete="phone"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                {activSendBtn}
                <Link
                  className="w-full mt-5 bg-orange-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-center font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                  type="button"
                  to={"/shoppingCart"}
                >
                  <span>
                    <RewindIcon className="h-6 absolute  " />
                  </span>
                  Retour Panier
                </Link>
              </form>
            </div>
          </section>
        </main>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Bravo, Votre réservation a été envoyé !
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        <br /> <br />
                        Vous allez recevoir une confirmation par Email. <br />
                        N'hésitez pas a vérifier dans vos{" "}
                        <strong> spams </strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <Link
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    to={{
                      pathname: `/produits`,
                      state: { from: false },
                    }}
                  >
                    Ok
                  </Link>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
