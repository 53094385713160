import React from "react";
import { Link } from "react-router-dom";

export default function PaveCard({ pave }) {
  return (
    <li className="relative">
      <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
        <img
          src={pave.images[0].src}
          alt=""
          className="object-cover pointer-events-none group-hover:opacity-75"
        />
        <Link
          to={`/dashboard/pave/${pave._id}`}
          type="button"
          className="absolute inset-0 focus:outline-none"
        >
          <span className="sr-only">Voir plus {pave.nomPave}</span>
        </Link>
      </div>
      <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
        {pave.nomPave}
      </p>
      <p className="block text-sm font-medium text-gray-500 pointer-events-none">
        {pave.gamme}
      </p>
    </li>
  );
}
