import axios from "axios";
import "../axios";

export const getStatistique = async () => {
  let data = await axios.get("/users/statistique");
  return data;
};

export const sendContactMail = async (form) => {
  let data = await axios.post("users/send-mail", form);
  return data;
};

export const forgotPassword = async (form) => {
  let data = await axios.post("users/forgotpassword", form);
  return data;
};

export const resetPassword = async (token, form) => {
  let data = await axios.post(`users/resetpassword/${token}`, form);
  return data;
};

export const sendDocument = async (form) => {
  let data = await axios.post("users/send-document", form);
  return data;
};
