import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const dayNames = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredii",
  "Samedi",
  "Dimanche",
];

export default function GraphDay({ day, month }) {
  const getDayName = (d) => {
    let data = dayNames[d.getDay()];
    return data;
  };

  const dayData = day.map((element) => {
    return {
      id: 0,
      MontantTotal: element.MontantTotal,
      label: getDayName(new Date(element._id)),
    };
  });

  return (
    <div>
      <div className="grid grid-cols-1">
        <div style={{ width: "90%", height: 450 }}>
          <ResponsiveContainer>
            <BarChart width={600} height={300} data={dayData}>
              <XAxis dataKey="label" stroke="#000" />
              <YAxis />
              <Tooltip />
              <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
              <Bar dataKey="MontantTotal" fill="#8884d8" barSize={30} />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div></div>
      </div>
    </div>
  );
}
