import React, { useState, useEffect } from "react";

import Loader from "../../components/shared/Loader";
import { getAllPave } from "../../api/paveApi";
import ProductCard from "../../components/productPave/ProductCard";
import Product2Card from "../../components/productPave/Product2Card";
import { getAllMateriel } from "../../api/materielApi";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const [paves, setPaves] = useState([]);
  const [materiaux, setMateriaux] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fecthPave = async () => {
    try {
      const data = await getAllPave();
      setPaves(data.data);
      setIsLoading(false);
      console.log("paves", data.data);
    } catch (error) {
      setIsLoading(false);
      console.log("Error ", error);
    }
  };
  const fecthMaterieaux = async () => {
    try {
      const data = await getAllMateriel();
      setMateriaux(data.data.materiels);
      setIsLoading(false);
      console.log("materiels", data.data.materiels);
    } catch (error) {
      setIsLoading(false);
      console.log("Error ", error);
    }
  };

  useEffect(() => {
    fecthPave();
    fecthMaterieaux();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-white mb-12 ">
      <main>
        {/* Hero section */}
        <div className="relative">
          {/* Background image and overlap */}
          <div
            aria-hidden="true"
            className="hidden absolute inset-0 sm:flex sm:flex-col"
          >
            <div className="flex-1 relative w-full bg-gray-800">
              <div className="absolute inset-0 overflow-hidden">
                <img
                  src="https://www.senini.it/wp-content/uploads/2020/01/Lastra_-1.jpg"
                  alt=""
                  className="w-full h-full object-center object-cover"
                />
              </div>
              <div className="absolute inset-0 bg-gray-900 opacity-20" />
            </div>
            <div className="w-full bg-white h-10 md:h-40 lg:h-10" />
          </div>

          <div className="relative max-w-3xl mx-auto pb-5 px-4 text-center sm:pb-0 sm:px-6 lg:px-8">
            {/* Background image and overlap */}
            <div
              aria-hidden="true"
              className="absolute inset-0 flex flex-col sm:hidden"
            >
              <div className="flex-1 relative w-full bg-gray-300">
                <div className="absolute inset-0 overflow-hidden">
                  <img
                    src="https://www.senini.it/wp-content/uploads/2020/01/Lastra_-1.jpg"
                    alt=""
                    className="w-full h-full object-center object-cover"
                  />
                </div>
                <div className="absolute inset-0 bg-gray-900 opacity-50" />
              </div>
              <div className="w-full bg-white h-10" />
            </div>
            <div className="relative py-32">
              <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl md:text-6xl">
                Les gammes de Pavés
              </h1>
              <div className="mt-4 sm:mt-6">
                {/* <Link
                  to="#"
                  className="inline-block bg-indigo-600 border border-transparent rounded-md py-3 px-8 font-medium text-white hover:bg-indigo-700"
                >
                  Shop Collection
                </Link> */}
              </div>
            </div>
          </div>
          {/* 
          <section
            aria-labelledby="collection-heading"
            className="-mt-96 relative sm:mt-0"
          >
            <h2 id="collection-heading" className="sr-only">
              Collections
            </h2>
            <div className="max-w-md mx-auto grid grid-cols-1 gap-y-6 px-4 sm:max-w-7xl sm:px-6 sm:grid-cols-3 sm:gap-y-0 sm:gap-x-6 lg:px-8 lg:gap-x-8">
              {collections.map((collection) => (
                <div
                  key={collection.name}
                  className="group relative h-96 bg-white rounded-lg shadow-xl sm:h-auto sm:aspect-w-4 sm:aspect-h-5"
                >
                  <div>
                    <div
                      aria-hidden="true"
                      className="absolute inset-0 rounded-lg overflow-hidden "
                    >
                      <div className="absolute inset-0 overflow-hidden group-hover:opacity-75 ">
                        <img
                          src={collection.imageSrc}
                          alt={collection.imageAlt}
                          className="w-full h-full object-center object-cover"
                        />
                      </div>
                      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50" />
                    </div>
                    <div className="absolute inset-0 rounded-lg p-6 flex items-end">
                      <div>
                        <p aria-hidden="true" className="text-sm text-white">
                          Shop the collection
                        </p>
                        <h3 className="mt-1 font-semibold text-white">
                          <a href={collection.href}>
                            <span className="absolute inset-0" />
                            {collection.name}
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section> */}
        </div>

        {isLoading ? (
          <Loader />
        ) : (
          <div className="w-full">
            {paves.length === 0 ? (
              <div className=" p-5 text-center">
                <p className="font-medium text-2xl uppercase">
                  Chargement des Pavés, ...
                </p>
              </div>
            ) : (
              <section aria-labelledby="trending-heading">
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center">
                    <span className="px-3 bg-white text-2xl font-medium text-gray-900 uppercase">
                      Liste des Pavés
                    </span>
                  </div>
                </div>
                <div className="max-w-7xl mx-auto px-auto sm:px-2 lg:px-2 py-2">
                  <div className="m-2 mt-4 grid grid-cols-2 gap-x-2 sm:gap-x-2 md:grid-cols-5 lg:grid-cols-6  md:gap-y-0 lg:gap-x-2">
                    {paves.map((pave, index) => (
                      <ProductCard key={index} pave={pave} />
                    ))}
                  </div>
                </div>
              </section>
            )}
            {materiaux.length === 0 ? (
              <div className=" p-5 m-5  text-center">
                <p className="font-medium text-2xl uppercase">
                  Chargement des matériaux, ...
                </p>
              </div>
            ) : (
              <section aria-labelledby="trending-heading ">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-10">
                  <div className="relative">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                      <span className="px-3 bg-white text-2xl font-medium text-gray-900 py-5 uppercase">
                        Liste des Matériaux
                      </span>
                    </div>
                  </div>
                  <div className="mt-3 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
                    {materiaux.map((materiel, index) => (
                      <Product2Card key={index} materiel={materiel} />
                    ))}
                  </div>
                </div>
              </section>
            )}
          </div>
        )}
      </main>
    </div>
  );
}
