import React, { useState } from "react";
import { useTransition, animated } from "@react-spring/web";
import styles from "./styles.module.css";
import logo from "../../assets/img/logos.png";

const slides = [
  "https://i.ibb.co/wh6b4XF/PHOTO-2022-05-15-04-18-17.jpg",
  "https://i.ibb.co/zQ2psyF/PHOTO-2021-05-16-14-29-27-2.jpg",
  "https://i.ibb.co/Y7Bm7Tt/PHOTO-2021-05-16-14-29-27-3.jpg",
];

const images = [
  "https://i.ibb.co/wh6b4XF/PHOTO-2022-05-15-04-18-17.jpg",
  "https://i.ibb.co/zQ2psyF/PHOTO-2021-05-16-14-29-27-2.jpg",
  "https://i.ibb.co/Y7Bm7Tt/PHOTO-2021-05-16-14-29-27-3.jpg",
  "https://i.ibb.co/R2dT623/PHOTO-2022-05-15-04-15-38.jpg",
  "https://i.ibb.co/QCMWjdP/PHOTO-2022-05-15-04-16-32-2.jpg",
  "https://i.ibb.co/GHfVB7w/PHOTO-2022-05-15-04-16-32.jpg",
];

export default function Animation() {
  const [index, set] = useState(0);
  const transitions = useTransition(index, {
    key: index,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 4000 },
    onRest: (_a, _b, item) => {
      if (index === item) {
        set((state) => (state + 1) % images.length);
      }
    },
    exitBeforeEnter: false,
  });
  return (
    <>
      <div className="h-96 m-auto text-center lg:mt-36 ">
        <img
          src={logo}
          style={{
            height: "100px",
            margin: "auto",
            marginTop: "25px",
            objectPosition: "bottom",
          }}
          alt="logo"
        />
        <div className="mt-5 text-2xl italic text-gray-800 font-extrabold">
          Vente et Pose de pavé
        </div>
      </div>
      <div className="flex fill center" style={{ minHeight: "50%" }}>
        {transitions((style, i) => (
          <animated.div
            className={styles.bg}
            style={{
              ...style,
              // backgroundImage: `url(https://images.unsplash.com/${slides[i]}?w=1920&q=80&auto=format&fit=crop)`,
              backgroundImage: `url(${images[i]})`,
            }}
          />
        ))}
      </div>
    </>
  );
}
