import React, { useEffect, useState, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, ExclamationIcon } from "@heroicons/react/outline";
import Loader from "../../components/shared/Loader";
import { deleteFacture, getFactureInfo } from "../../api/factureApi";
import formatMoney from "../../utils/FormatMoney";
import logo from "../../assets/img/logo-senepave.png";

export default function DetailFacture() {
  const { id } = useParams();
  const [facture, setFacture] = useState({});
  const [loading, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const getfacture = async () => {
    getFactureInfo(id)
      .then((data) => {
        setFacture(data.data.facture);
        console.log("DATA", data.data);
        setLoader(false);
      })
      .catch((error) => {
        console.log("ERROR ");
        setOpen(true);
      });
  };

  useEffect(() => {
    getfacture();
  }, []);
  function getSumHt(array, column) {
    let values = array.map((item) => parseInt(item[column]) || 0);
    let montant = values.reduce((a, b) => a + b);
    return formatMoney(montant);
  }
  function getSumTtc(array, column) {
    let values = array.map((item) => parseInt(item[column]) || 0);
    let pht = values.reduce((a, b) => a + b);
    let t = (pht * 20) / 100;
    let montant = pht + t;
    return formatMoney(montant);
  }
  function printCertificate() {
    const printContents = document.getElementById("certificate").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }
  const onDelete = async () => {
    setLoader(true);
    await deleteFacture(id);
    setLoader(false);
    setOpen(true);
  };
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8"></div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Detail de la facture
                </h3>
                <div className="flex justify-end">
                  <button
                    onClick={() => {
                      printCertificate();
                    }}
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Imprimer
                  </button>
                </div>
                <div style={{ width: "100%" }}>
                  <section
                    id="certificate"
                    aria-labelledby="applicant-information-title"
                  >
                    <div className="bg-white shadow sm:rounded-lg">
                      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Information du vendeur
                            </dt>
                            <img
                              alt="logo"
                              src={logo}
                              style={{
                                width: "60%",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            />
                            <p style={{ maxWidth: 350 }}>
                              <span className=" font-medium text-gray-500">
                                {" "}
                                Adresse :{" "}
                              </span>
                              Adresse : 19 Av. Alsace Lorraine, 38110 La
                              Tour-du-Pin, France
                            </p>
                            <p>
                              <span className=" font-medium text-gray-500">
                                {" "}
                                TEL :{" "}
                              </span>
                              04 48 20 27 42
                            </p>
                            <p>
                              <span className=" font-medium text-gray-500">
                                {" "}
                                Email:{" "}
                              </span>
                              contact@senepave.com
                            </p>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500"></dt>
                            <dd className="mt-1 text-sm text-gray-900"></dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500"></dt>
                            <dd className="mt-1 text-sm text-gray-900"></dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Informtion du client
                            </dt>
                            <p>
                              <span className=" font-medium text-gray-500">
                                {" "}
                                Nom :{" "}
                              </span>
                              {facture.nomClient}
                            </p>{" "}
                            <p style={{ maxWidth: 350 }}>
                              <span className=" font-medium text-gray-500">
                                {" "}
                                Adresse :{" "}
                              </span>
                              {facture.adresse} {facture.city}
                            </p>
                            <p>
                              <span className=" font-medium text-gray-500">
                                {" "}
                                email :{" "}
                              </span>
                              {facture.email}
                            </p>
                            <p>
                              <span className=" font-medium text-gray-500">
                                {" "}
                                TEL :{" "}
                              </span>
                              {facture.phone}
                            </p>
                          </div>
                          <div
                            style={{ width: "100%" }}
                            className="sm:col-span-2"
                          >
                            <dt
                              style={{ width: "100%" }}
                              className="text-sm font-medium text-gray-500"
                            ></dt>
                            <dd
                              style={{ width: "100%" }}
                              className="mt-1 text-sm text-gray-900"
                            ></dd>
                          </div>
                          <div className="sm:col-span-2">
                            <p>
                              <span className=" font-medium text-gray-500">
                                Facture N:{" "}
                              </span>
                              {"0000".substr(
                                String(facture.numeroFacture).length
                              ) + facture.numeroFacture}
                            </p>
                            <p className="mb-5">
                              <span className="font-medium text-gray-500">
                                Date :{" "}
                              </span>
                              {new Date(facture.updatedAt).toLocaleString(
                                "fr-FR"
                              )}
                            </p>
                            <dd className="mt-1 text-sm text-gray-900">
                              <table className="mt-5 min-w-full divide-y divide-gray-500">
                                <thead className="bg-gray-900 text-white">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                                    >
                                      Nom du pave
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                                    >
                                      Detail
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                                    >
                                      Prix unitaire
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                                    >
                                      Quantité
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                                    >
                                      Total
                                    </th>
                                    <th
                                      scope="col"
                                      className="relative px-6 py-3"
                                    >
                                      <span className="sr-only">Edit</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                  {facture.commandes.map((commande, i) => (
                                    <tr
                                      key={i}
                                      className={
                                        i % 2 === 0 ? "bg-white" : "bg-gray-50"
                                      }
                                    >
                                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                        {commande.produitName}
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {commande.detail}
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {commande.prix} €
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {commande.quantite} {commande.unite}
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {formatMoney(commande.sousTotal)}€
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"></td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </dd>
                          </div>
                        </dl>
                      </div>
                      <div
                        className="bg-gray-200"
                        style={{ width: "100%", height: 2 }}
                      ></div>
                      <div className="divide-y flex justify-end">
                        <dl className="mt-2 mr-5  border-t border-b border-gray-200 divide-y divide-gray-200">
                          <div className=" py-1 flex  text-sm font-medium">
                            <dt className="mr-2 text-gray-500 text-white">
                              Montant HT: {"  "}
                            </dt>
                            <dd className="text-gray-900">
                              {" "}
                              {getSumHt(facture.commandes, "sousTotal")} €
                            </dd>
                          </div>
                          <div className=" py-1 flex  text-sm font-medium">
                            <dt className="mr-2 text-gray-500">Tva: </dt>
                            <dd className="text-gray-900"> 20%</dd>
                          </div>
                          <div className=" py-1 flex  text-sm font-medium">
                            <dt className="mr-2 text-gray-500">Montant TTC:</dt>
                            <dd className="text-gray-900">
                              {" "}
                              {getSumTtc(facture.commandes, "sousTotal")}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Erreur
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Facture n'existe plus! <br />
                        Veuillez nous contacter sur contact@senepave.com
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <Link
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    to="/"
                  >
                    Ok
                  </Link>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
