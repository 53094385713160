/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useState, useEffect, Fragment } from "react";
import { Disclosure, Tab } from "@headlessui/react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { MinusSmIcon, PlusSmIcon, StarIcon } from "@heroicons/react/outline";

import { useParams, useHistory, Link } from "react-router-dom";
import { getPaveInfo } from "../../api/paveApi";
import Loader from "../../components/shared/Loader";
import { useSelector, useDispatch } from "react-redux";
import { addDevis } from "../../redux/store/devisStore";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import formatMoney from "../../utils/FormatMoney";
import Product2Card from "../../components/productPave/Product2Card";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Productdetail() {
  const devisData = useSelector((state) => state.devisStore.devis);
  const dispatch = useDispatch();
  const { id } = useParams();

  let history = useHistory();
  const [pave, setPave] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState({
    taille: "",
    couleur: "",
    epaisseur: "",
  });

  const [open1, setOpen1] = useState(false);
  const [product, setProduct] = useState({
    taille: "",
    couleur: "",
    epaisseur: "",
  });
  const [materiaux, setMateriaux] = useState([]);

  const handleMChange = (name, value) => {
    if (product[name] === value) {
      setProduct({ ...product, [name]: "" });
    } else {
      setProduct({ ...product, [name]: value });
    }
  };

  // const fecthMaterieaux = async () => {
  //   try {
  //     const data = await getAllMateriel();
  //     setMateriaux(data.data.materiels);
  //     setIsLoading(false);
  //     console.log("materiels", data.data.materiels);
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.log("Error ", error);
  //   }
  // };

  const getPave = async () => {
    try {
      let data = await getPaveInfo(id);
      console.log("PAvé Spe", data.data);
      setPave(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const addPanier = () => {
    if (
      product.taille === "" ||
      product.epaisseur === "" ||
      product.couleur === ""
    ) {
      if (product.taille === "") {
        setMessage((mesage) => ({
          ...message,
          taille: " - Vous n'avez pas choisi une taille pour le pave",
        }));
      } else {
        setMessage((mesage) => ({
          ...message,
          taille: "",
        }));
      }
      if (product.couleur === "") {
        setMessage((message) => ({
          ...message,
          couleur: "- Vous n'avez pas choisi de couleur pour le pave",
        }));
      } else {
        setMessage((message) => ({
          ...message,
          couleur: "",
        }));
      }
      if (product.epaisseur === "") {
        setMessage((message) => ({
          ...message,
          epaisseur: "- Vous n'avez pas choisi  d'epaisseur pour le pave ",
        }));
      } else {
        setMessage((message) => ({
          ...message,
          epaisseur: " ",
        }));
      }
      setOpen1(true);
    } else {
      setIsLoading(true);
      let temp = {
        id: devisData.length,
        couleur: product.couleur,
        images: pave.images,
        taille: product.taille,
        description: pave.description,
        epaisseur: product.epaisseur,
        gamme: pave.gamme,
        nom: pave.nomPave,
        prixVente: pave.prixVente,
        tarifPose: pave.tarifPose,
        quantite: 1,
        pave: true,
      };
      dispatch(addDevis(temp));
      NotificationManager.success(
        "Pavé ajouter dans le panier avec succes",
        "Succès",
        500
      );
      setProduct({
        taille: "",
        couleur: "",
        epaisseur: "",
      });
      setTimeout(() => {
        history.push("/shoppingcart");
      }, 2000);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // fecthMaterieaux();
    getPave();
  }, []);

  return (
    <div className="bg-white min-h-screen">
      <div className="mt-5 text-left lg:ml-25 px-20">
        <Link
          className=" flex-1 text-orange-600 font-semibold underline text-lg"
          // onClick={() => history.push("/shoppingcart")}
          to="/produits"
        >
          Retour
        </Link>
      </div>
      <NotificationContainer />
      <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
            <Tab.Group as="div" className="flex flex-col-reverse">
              <div className=" mt-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none">
                <Tab.List className="grid grid-cols-4 gap-6">
                  {pave &&
                    pave.images.map((image) => (
                      <Tab
                        key={image._id}
                        className="relative h-24 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50"
                      >
                        {({ selected }) => (
                          <>
                            <span className="sr-only">{image.name}</span>
                            <span className="absolute inset-0 rounded-md overflow-hidden">
                              <img
                                src={image.src}
                                alt={image.name}
                                className="w-full h-full object-center object-cover"
                              />
                            </span>
                            <span
                              className={classNames(
                                selected
                                  ? "ring-indigo-500"
                                  : "ring-transparent",
                                "absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none"
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </Tab>
                    ))}
                </Tab.List>
              </div>

              <Tab.Panels className="w-full aspect-w-1 aspect-h-1">
                {pave.images.map((image) => (
                  <Tab.Panel key={image._id}>
                    <img
                      src={image.src}
                      alt={image.alt}
                      className="w-full h-full object-center object-cover sm:rounded-lg"
                    />
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>

            <div className="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
              <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 uppercase">
                {pave.nomPave}
              </h1>
              <div className="mt-3">
                <h2 className="sr-only">pave information</h2>
                <p className="text-2xl text-gray-700 italic">
                  {formatMoney(pave.prixVente)} € / m2
                </p>
              </div>

              {/* Reviews */}
              <div className="mt-3">
                <h3 className="sr-only">Reviews</h3>
                <div className="flex items-center">
                  <div className="flex items-center">
                    {/* Notation des Etoiles */}
                    {[0, 1, 2, 3, 4].map((rating) => (
                      <StarIcon
                        key={rating}
                        className={classNames(
                          pave.rating > rating
                            ? "text-indigo-500"
                            : "text-gray-300",
                          "h-5 w-5 flex-shrink-0"
                        )}
                        aria-hidden="true"
                      />
                    ))}
                  </div>
                  <p className="sr-only">{pave.rating} out of 5 stars</p>
                </div>
              </div>

              <div className="mt-6">
                <h3 className="sr-only">Description</h3>

                <div
                  className="text-justify text-gray-700 space-y-6"
                  dangerouslySetInnerHTML={{ __html: pave.description }}
                />
              </div>

              {/* Colors */}

              <section aria-labelledby="details-heading" className="mt-12">
                <h2 id="details-heading" className="sr-only">
                  Additional details
                </h2>

                <div className="border-t divide-y divide-gray-200">
                  <Disclosure as="div">
                    {({ open }) => (
                      <>
                        <h3>
                          <Disclosure.Button className="group relative w-full py-6 flex justify-between items-center text-left">
                            <span
                              className={classNames(
                                open ? "text-indigo-600" : "text-gray-900",
                                "text-sm font-medium"
                              )}
                            >
                              Couleurs :
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusSmIcon
                                  className="block h-6 w-6 text-indigo-400 group-hover:text-indigo-500"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusSmIcon
                                  className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>

                        <div className="flex">
                          <Disclosure.Panel
                            as="div"
                            className="pb-6 prose prose-sm"
                          >
                            <div className="flex flex-row  space-x-2">
                              {pave.couleurs.map((item) => (
                                <button
                                  onClick={() => handleMChange("couleur", item)}
                                  key={item}
                                  type="button"
                                  className={classNames(
                                    product.couleur === item
                                      ? "border border-gray-600 shadow-sm text-xs font-medium rounded text-white bg-yellow-500 hover:border-indigo-500 hover:border-2 focus:outline-none focus:ring-2 focus:ring-offset-2 "
                                      : "border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                                    "ml-2 inline-flex items-center px-2.5 py-1.5"
                                  )}
                                >
                                  {item}
                                </button>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </div>
                      </>
                    )}
                  </Disclosure>
                  <Disclosure as="div">
                    {({ open }) => (
                      <>
                        <h3>
                          <Disclosure.Button className="group relative w-full py-6 flex justify-between items-center text-left">
                            <span
                              className={classNames(
                                open ? "text-indigo-600" : "text-gray-900",
                                "text-sm font-medium"
                              )}
                            >
                              Epaisseur disponible :
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusSmIcon
                                  className="block h-6 w-6 text-indigo-400 group-hover:text-indigo-500"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusSmIcon
                                  className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel
                          as="div"
                          className="pb-6 prose prose-sm"
                        >
                          {pave.epaisseurs.map((item) => (
                            <button
                              onClick={() => handleMChange("epaisseur", item)}
                              key={item}
                              type="button"
                              className={classNames(
                                product.epaisseur === item
                                  ? "border border-gray-600 shadow-sm text-xs font-medium rounded text-white bg-yellow-500 hover:border-indigo-500 hover:border-2 focus:outline-none focus:ring-2 focus:ring-offset-2 "
                                  : "border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                                "ml-2 inline-flex items-center px-2.5 py-1.5"
                              )}
                            >
                              {item}
                            </button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <Disclosure as="div">
                    {({ open }) => (
                      <>
                        <h3>
                          <Disclosure.Button className="group relative w-full py-6 flex justify-between items-center text-left">
                            <span
                              className={classNames(
                                open ? "text-indigo-600" : "text-gray-900",
                                "text-sm font-medium"
                              )}
                            >
                              Taille disponible:
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusSmIcon
                                  className="block h-6 w-6 text-indigo-400 group-hover:text-indigo-500"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusSmIcon
                                  className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>

                        <Disclosure.Panel
                          as="div"
                          className="pb-6 prose prose-sm"
                        >
                          <p>
                            {pave.tailles.map((item) => (
                              <button
                                onClick={() => handleMChange("taille", item)}
                                key={item}
                                type="button"
                                className={classNames(
                                  product.taille === item
                                    ? "border border-gray-600 shadow-sm text-xs font-medium rounded text-white bg-yellow-500 hover:border-indigo-500 hover:border-2 focus:outline-none focus:ring-2 focus:ring-offset-2"
                                    : "border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                                  "ml-2 inline-flex items-center px-2.5 py-1.5"
                                )}
                              >
                                {item}
                              </button>
                            ))}
                          </p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
              </section>

              <div className=" text-center justify-center  mt-10 flex sm:flex-col1">
                <button
                  type="submit"
                  className="max-w-xs flex-1 bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 sm:w-full"
                  // onClick={() => history.push("/shoppingcart")}
                  onClick={addPanier}
                >
                  Ajouté au Panier
                </button>
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
              {materiaux.map((materiel, index) => (
                <Product2Card key={index} materiel={materiel} />
              ))}
            </div>
          </div>
        )}
      </div>
      <Transition.Root show={open1} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen1}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Error
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {" "}
                        <span className="font-medium text-red-500 ">
                          {message.taille}
                        </span>
                      </p>
                      <p className="text-sm text-gray-500">
                        <span className="font-medium text-red-500 ">
                          {message.couleur}
                        </span>
                      </p>
                      <p className="text-sm text-gray-500">
                        <span className="font-medium text-red-500 ">
                          {message.epaisseur}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen1(false)}
                  >
                    ok
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
