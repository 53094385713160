/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useState, useEffect, Fragment } from "react";
import { Disclosure, RadioGroup, Tab } from "@headlessui/react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, ExclamationIcon } from "@heroicons/react/outline";
import {
  MinusSmIcon,
  PlusSmIcon,
  StarIcon,
  HeartIcon,
} from "@heroicons/react/outline";
import { products } from "../../components/shared/productsItems";
import { useParams, useHistory, Link } from "react-router-dom";
import { getmaterielInfo } from "../../api/materielApi";
import Loader from "../../components/shared/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
  addDevis,
  deleteDevis,
  resetDevis,
} from "../../redux/store/devisStore";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { getMaterielInfo } from "../../api/materielApi";
import formatMoney from "../../utils/FormatMoney";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DetaiMateriel() {
  const devisData = useSelector((state) => state.devisStore);
  const dispatch = useDispatch();
  const { id } = useParams();
  let history = useHistory();
  const [materiel, setMateriel] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [quantite, setQuantite] = useState(1);

  const [open1, setOpen1] = useState(false);

  const getMateriel = async () => {
    try {
      let data = await getMaterielInfo(id);
      console.log(data.data);
      setMateriel(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const addPanier = async () => {
    console.log(quantite);
    if (quantite <= 0 || quantite == "") {
      setOpen1(true);
    } else {
      let temp = {
        id: devisData.length,
        couleur: null,
        images: materiel.images,
        taille: null,
        description: materiel.description,
        epaisseur: null,
        gamme: null,
        nom: materiel.name,
        prixVente: materiel.prixUnitaire,
        tarifPose: null,
        quantite: quantite,
        pave: false,
      };
      await dispatch(addDevis(temp));
      NotificationManager.success(
        "Materiel ajouter dans le panier avec succes",
        "Succès",
        500
      );
      history.push("/shoppingcart");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getMateriel();
  }, []);

  return (
    <div className="bg-white min-h-screen">
      <div className="mt-5 text-left lg:ml-25 px-20">
        <Link
          className=" flex-1 text-orange-600 font-semibold underline text-lg"
          // onClick={() => history.push("/shoppingcart")}
          to="/produits"
        >
          Retour
        </Link>
      </div>
      <h1 className="text-center mt-5 text-lg "> Matériel</h1>
      <NotificationContainer />
      <div className="max-w-2xl mx-auto py-5 px-4 sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
            <Tab.Group as="div" className="flex flex-col-reverse">
              <div className="hidden mt-6 w-full max-w-lg mx-auto sm:block lg:max-w-none h-20">
                <Tab.List className="grid grid-cols-4 gap-6">
                  {materiel &&
                    materiel.images.map((image) => (
                      <Tab
                        key={image._id}
                        className="relative h-28 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50"
                      >
                        {({ selected }) => (
                          <>
                            <span className="sr-only">{image.name}</span>
                            <span className="absolute inset-0 rounded-md overflow-hidden">
                              <img
                                src={image.src}
                                alt={image.name}
                                className="w-full h-full object-center object-cover"
                              />
                            </span>
                            <span
                              className={classNames(
                                selected
                                  ? "ring-indigo-500"
                                  : "ring-transparent",
                                "absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none"
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </Tab>
                    ))}
                </Tab.List>
              </div>

              <Tab.Panels className="w-full aspect-w-1 aspect-h-1">
                {materiel.images.map((image) => (
                  <Tab.Panel key={image._id}>
                    <img
                      src={image.src}
                      alt={image.alt}
                      className="w-full h-full object-center object-cover sm:rounded-lg"
                    />
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>

            <div className="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
              <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 uppercase">
                {materiel.name}
              </h1>
              <div className="mt-3">
                <h2 className="sr-only">materiel information</h2>
                <p className="text-2xl text-gray-700 italic">
                  {formatMoney(materiel.prixUnitaire)} € / {materiel.unite}
                </p>
              </div>

              {/* Reviews */}
              <div className="mt-3">
                <h3 className="sr-only">Reviews</h3>
                <div className="flex items-center">
                  <div className="flex items-center">
                    {[0, 1, 2, 3, 4].map((rating) => (
                      <StarIcon
                        key={rating}
                        className={classNames(
                          true ? "text-indigo-500" : "text-gray-300",
                          "h-5 w-5 flex-shrink-0"
                        )}
                        aria-hidden="true"
                      />
                    ))}
                  </div>
                  <p className="sr-only">{materiel.rating} out of 5 stars</p>
                </div>
              </div>

              <div className="mt-6">
                <h3 className="sr-only">Description</h3>

                <div
                  className="text-justify text-gray-700 space-y-6"
                  dangerouslySetInnerHTML={{ __html: materiel.description }}
                />
              </div>

              {/* Colors */}

              <section aria-labelledby="details-heading" className="mt-12">
                <h2 id="details-heading" className="sr-only">
                  Additional details
                </h2>
              </section>

              <div className=" text-center justify-center  mt-10 flex sm:flex-col1">
                <input
                  type="number"
                  min="0"
                  value={quantite}
                  style={{ width: 150 }}
                  className="rounded-md border m-2 border-gray-300 py-1.5 text-base leading-5 font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={(e) => {
                    setQuantite(e.target.value);
                  }}
                />
                <button
                  type="submit"
                  className="max-w-xs flex-1 bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 sm:w-full"
                  // onClick={() => history.push("/shoppingcart")}
                  onClick={addPanier}
                >
                  Ajouté au Panier
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Transition.Root show={open1} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen1}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Error
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {" "}
                        <span className="font-medium text-red-500 ">
                          La quantite doit etre superieur a 0
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen1(false)}
                  >
                    ok
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
