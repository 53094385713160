import axios from "axios";
import "../axios";

export const addUser = async (form) => {
  let data = await axios.post("/users/", form);
  return data;
};

export const getAllUser = async () => {
  let data = await axios.get("/users/");
  return data;
};

export const updateUser = async (id, form) => {
  let data = await axios.patch(`/users/${id}`, form);
  return data;
};

export const getUserInfo = async (id) => {
  let data = await axios.get(`/users/${id}`);
  return data;
};
export const deleteUser = async (id) => {
  let data = await axios.delete(`/users/${id}`);
  return data;
};
