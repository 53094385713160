import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "../components/adminLayout/Header";
import SideBar from "../components/adminLayout/SideBar";
import {
  DocumentPage,
  DashboardPage,
  AddMaterielPage,
  UpdateMaterielPage,
  MaterielPage,
  DetailMaterielPage,
  DetailPavePage,
  PavePage,
  AddPavePage,
  UpdatePavePage,
  CommandePage,
  UpdateCommande,
  DetailCommande,
  AddCommande,
  CommandePageTraite,
  DetailFacturePage,
  FacturePage,
  UpdateFacturePage,
  AddFacture,
  ClientPage,
  UsersPage,
  AddClient,
  DetailClient,
} from "../pages/admin";

const AdminRoute = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className="min-h-screen bg-gray-100">
      <Router>
        <div>
          <SideBar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
          <div className="md:pl-64 flex flex-col">
            <Header setSidebarOpen={setSidebarOpen} />
            <main className="flex-1 pb-8 ">
              <Switch>
                {/* materiel router */}
                <Route
                  component={DetailMaterielPage}
                  path="/dashboard/materiels/:id"
                />
                <Route
                  exact
                  component={MaterielPage}
                  path="/dashboard/materiels"
                />
                <Route
                  component={AddMaterielPage}
                  path="/dashboard/addmateriel"
                />
                <Route
                  component={UpdateMaterielPage}
                  path="/dashboard/updatemateriel/:id"
                />
                {/* pave router */}
                <Route component={DetailPavePage} path="/dashboard/pave/:id" />
                <Route exact component={PavePage} path="/dashboard/paves" />
                <Route component={AddPavePage} path="/dashboard/addpave" />
                {/*  Client Routes */}
                <Route component={DetailClient} path="/dashboard/client/:id" />
                <Route exact component={ClientPage} path="/dashboard/clients" />
                <Route exact component={UsersPage} path="/dashboard/users" />
                <Route component={AddClient} path="/dashboard/addclient" />

                <Route
                  component={UpdatePavePage}
                  path="/dashboard/updatepave/:id"
                />
                {/* Facture  */}
                <Route
                  component={DetailFacturePage}
                  path="/dashboard/factures/:id"
                />
                <Route
                  exact
                  component={FacturePage}
                  path="/dashboard/factures"
                />
                <Route
                  exact
                  component={AddFacture}
                  path="/dashboard/addfacture/:id"
                />
                <Route
                  component={UpdateFacturePage}
                  path="/dashboard/updatefactures/:id"
                />
                {/* document */}
                <Route
                  exact
                  component={DocumentPage}
                  path="/dashboard/documents"
                />
                {/* Commande router */}
                <Route
                  component={DetailCommande}
                  path="/dashboard/commande/:id"
                />
                <Route
                  exact
                  component={CommandePage}
                  path="/dashboard/commandes"
                />
                <Route
                  exact
                  component={CommandePageTraite}
                  path="/dashboard/commandes-traitees"
                />
                <Route component={AddCommande} path="/dashboard/addCommande" />
                <Route
                  component={UpdateCommande}
                  path="/dashboard/updatecommande/:id"
                />
                {/* Dashboard */}
                <Route exact component={DashboardPage} path="/DashboardPage" />
                <Route exact component={DashboardPage} path="*" />
              </Switch>
            </main>
          </div>
        </div>
      </Router>
    </div>
  );
};

export default AdminRoute;
