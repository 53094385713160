import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllCommandeEnded } from "../../api/commandeApi";
import NavigComponent from "../../components/commandes/NavigComponent";
import TableauCommande from "../../components/commandes/TableauCommande";
import Loader from "../../components/shared/Loader";

export default function CommandePageTraite() {
  const [loading, setLoading] = useState(false);
  const [commandes, setCommandes] = useState([]);
  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      let data = await getAllCommandeEnded();
      setCommandes(data.data.devis);
      setLoading(false);
    };
    getData();
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">Commandes</h1>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="mt-3">
              <Link
                to="/dashboard/addmateriel"
                type="button"
                className="mb-3 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Ajouter une commande
              </Link>
              <NavigComponent />
              {commandes.length === 0 ? (
                <h2 className="text-center mt-10 mb-8 text-lg leading-6 font-medium text-gray-900">
                  Pas de commandes enregistrées
                </h2>
              ) : (
                <>
                  <div className="flex flex-col">
                    <h1 className="m-2 text-center text-xl font-semibold text-gray-900">
                      Commandes traitées
                    </h1>
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Numero de la commande
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Nom du Client
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Email du client
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Prix total
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Status
                                </th>
                                <th scope="col" className="relative px-6 py-3">
                                  <span className="sr-only">Edit</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {commandes.map((commande, idx) => (
                                <TableauCommande
                                  key={idx}
                                  commande={commande}
                                  index={idx}
                                  state={true}
                                />
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
