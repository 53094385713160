import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import pave1 from "../../assets/img/asphalte.jpg";
// import captanticato from "../../assets/img/captanticato.png";
// import captcotto from "../../assets/img/captcotto.png";
// import captnaturelle from "../../assets/img/captcotto.png";

const posts = [
  {
    title: "Pavé Antico ©",
    to: "#",
    category: { name: "Antico", to: "#" },
    description:
      "Pavés à double couche, vieillis mécaniquement pour obtenir des bords irréguliers et arrondis, tels que ceux causés par le temps. La surface visible est réalisée avec un mélange de granulats siliceux soigneusement sélectionnés pour obtenir une finition homogène à grain fin, des couleurs vives et une haute résistance aux contraintes physico-chimiques.",
    date: "Mar 16, 2020",
    datetime: "2020-03-16",
    imageUrl:
      "https://i2.wp.com/www.senini.it/wp-content/uploads/2016/05/anticato.jpg?w=386&ssl=1",
  },
  {
    title: "Pavé Cotto ©",
    to: "#",
    category: { name: "Cotto", to: "#" },
    description:
      "Pavés monocouches, vieillis mécaniquement pour obtenir des bords irréguliers et arrondis, tels que ceux causés par le temps. Le mélange est réalisé avec un choix et un mélange minutieux de pigments pour donner au produit fini les tons chauds et naturels de la terre cuite. ",
    date: "Mar 10, 2020",
    datetime: "2020-03-10",
    imageUrl:
      "https://i0.wp.com/www.senini.it/wp-content/uploads/2016/05/cotto_antico.jpg?w=386&ssl=1",
  },
  {
    title: "Les pièrres Naturelle ©",
    to: "#",
    category: { name: "Pièrres Naturelle", to: "#" },
    description:
      "Pavés à double couche. La surface visible est réalisée avec un mélange de marbre concassé et de granit, mis en valeur par le procédé de grenaillage mécanique qui confère au produit une grande matérialité et l'aspect typique d'une pierre naturelle martelée.",
    date: "Feb 12, 2020",
    imageUrl:
      "https://i2.wp.com/www.senini.it/wp-content/uploads/2016/05/pietra_naturale.jpg?w=386&ssl=1",
  },
];

const SectionTwo = () => {
  useEffect(() => {}, []);

  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            Nos Gammes de produits en Vente
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Nos produits de fabrications 100% italienne garantissent une très
            bonne qualité de pose et un entretien facile et Durable
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none ">
          {posts.map((post) => (
            <div
              key={post.title}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden hover:-translate-x-2 hover:-translate-y-2 "
            >
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src={post.imageUrl}
                  alt="pavé"
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between ">
                <div className="flex-1">
                  <p className="text-sm font-medium text-red-600">
                    <Link to={post.category.to} className="hover:underline">
                      {post.category.name}
                    </Link>
                  </p>
                  <Link to={post.to} className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">
                      {post.title}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      {post.description}
                    </p>
                  </Link>
                </div>
                {/* <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <Link to={post.author.to}>
                      <span className="sr-only">{post.author.name}</span>
                      <img
                        className="h-10 w-10 rounded-full"
                        src={post.author.imageUrl}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      <Link to={post.author.to} className="hover:underline">
                        {post.author.name}
                      </Link>
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <time dateTime={post.datetime}>{post.date}</time>
                      <span aria-hidden="true">&middot;</span>
                      <span>{post.readingTime} read</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
