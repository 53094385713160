import axios from "axios";
import "../axios";

export const addPave = async (form) => {
  let data = await axios.post("/paves/", form);
  return data;
};

export const getAllPave = async (form) => {
  let data = await axios.get("/paves/");
  return data;
};

export const getPaveInfo = async (id) => {
  let data = await axios.get(`/paves/${id}`);
  return data;
};

export const deletePave = async (id) => {
  let data = await axios.delete(`/paves/${id}`);
  return data;
};

export const updatePave = async (id, form) => {
  let data = await axios.patch(`/paves/${id}`, form);
  return data;
};
