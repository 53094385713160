import axios from "axios";
import { useSelector } from "react-redux";
import "../axios";
import { currentToken } from "../redux/store/authStore";

export const GetAllDocument = async () => {
  let tokenData = useSelector(currentToken);

  if (tokenData) {
    axios.defaults.headers["x-access-token"] = tokenData;
  }
  let data = await axios.get("/document/");
  return data;
};

export const addDocument = async (form) => {
  let data = await axios.post("/document/", form);
  return data;
};

export const deleteDocument = async (id) => {
  let data = await axios.delete(`/document/${id}`);
  return data;
};
