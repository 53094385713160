import axios from "axios";
import "../axios";

export const addFacture = async (form) => {
  let data = await axios.post("/factures/", form);
  return data;
};

export const getAllFacture = async () => {
  let data = await axios.get("/factures/");
  return data;
};

export const getFactureInfo = async (id) => {
  let data = await axios.get(`/factures/${id}`);
  return data;
};

export const deleteFacture = async (id) => {
  let data = await axios.delete(`/factures/${id}`);
  return data;
};

export const sendFacture = async (form) => {
  let data = await axios.post("/factures/send-facture", form);
  return data;
};
