/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MaterielCard from "../../components/materiel/MaterielCard";
import { getAllMateriel } from "../../api/materielApi";
import Loader from "../../components/shared/Loader";
export default function MaterielPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getMateriel();
  }, []);
  const getMateriel = async () => {
    const data = await getAllMateriel();
    setData(data.data.materiels);
    setLoading(false);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">Matériels</h1>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="mt-3">
              <Link
                to="/dashboard/addmateriel"
                type="button"
                className="mb-3 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Ajouter un Materiel
              </Link>
              {data.length === 0 ? (
                <h2 className="text-center mt-10 mb-8 text-lg leading-6 font-medium text-gray-900">
                  Pas de matériaux enregistrés
                </h2>
              ) : (
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                  <h2 className="mb-8 text-lg leading-6 font-medium text-gray-900">
                    Liste des matériaux
                  </h2>
                  <ul
                    role="list"
                    className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
                  >
                    {data.map((file) => (
                      <MaterielCard key={file._id} file={file} />
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
