import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import formatMoney from "../../utils/FormatMoney";

export default function TableauCommande({ commande, index, state }) {
  useEffect(() => {}, []);
  function getSum(array, column) {
    let values = array.map((item) => parseInt(item[column]) || 0);
    let montant = values.reduce((a, b) => a + b);
    return formatMoney(montant);
  }

  return (
    <tr
      key={commande._id}
      className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
    >
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {"0000".substr(String(commande.numeroCommande).length) +
          commande.numeroCommande}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {commande.nomClient}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {commande.email}
      </td>
      {!commande.statut ? (
        <td className="px-6 py-4 whitespace-nowrap text-sm ">
          <span className="text-red-100 bg-red-600 p-2 rounded-lg">
            {" "}
            {commande.commandes
              ? getSum(commande.commandes, "sousTotal")
              : 0} €{" "}
          </span>
        </td>
      ) : (
        <td className="px-6 py-4 whitespace-nowrap text-sm ">
          <span className="text-green-100 bg-green-600 p-2 rounded-lg">
            {" "}
            {commande.commandes
              ? getSum(commande.commandes, "sousTotal")
              : 0} €{" "}
          </span>
        </td>
      )}

      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <Link
          to={{
            pathname: `/dashboard/commande/${commande._id}`,
            state: { from: state },
          }}
          className=" inline-flex items-center px-2 py-1 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Detail
        </Link>
      </td>
    </tr>
  );
}
