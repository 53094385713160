import axios from "axios";
import "../axios";

export const addCommande = async (form) => {
  let data = await axios.post("/devis/", form);
  return data;
};

export const getAllCommande = async () => {
  let data = await axios.get("/devis/");
  return data;
};
export const getAllCommandeEnded = async () => {
  let data = await axios.get("/devis/ended");
  return data;
};
export const updateCommade = async (id, form) => {
  let data = await axios.patch(`/devis/${id}`, form);
  return data;
};

export const getCommandeInfo = async (id) => {
  let data = await axios.get(`/devis/${id}`);
  return data;
};
export const deleteCommande = async (id) => {
  let data = await axios.delete(`/devis/${id}`);
  return data;
};

export const sendCommande = async (form) => {
  let data = await axios.post(`/devis/send-devis`, form);
  return data;
};
