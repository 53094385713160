import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { TrashIcon, CheckIcon } from "@heroicons/react/outline";
import { getMaterielInfo, updateMateriel } from "../../api/materielApi";
import Loader from "../../components/shared/Loader";

export default function UpdateMateriel() {
  const history = useHistory();
  const { id } = useParams();
  const [values, setValues] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState({});
  const [images, setImages] = useState([]);
  const [ids, setId] = useState([]);
  const [Isdelete, setIsDelete] = useState(false);
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const data = await getMaterielInfo(id);
      setValues({
        description: data.data.description,
        name: data.data.name,
        prixAchat: data.data.prixAchat,
        prixUnitaire: data.data.prixUnitaire,
        unite: data.data.unite,
      });
      setImages(data.data.images);
      let value = {
        _id: null,
        src: "https://cooking-test.s3.us-east-2.amazonaws.com/tattooImage/default.png",
      };
      setImages((images) => {
        return [...images, value];
      });
      setLoading(false);
    };
    getData();
  }, []);
  const handleChange = (e) => {
    e.preventDefault();
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const updateField = (item, e, i) => {
    let temp = [...images];
    let value = {
      _id: item._id ? item._id : 1,
      src: e.target.files[0],
      modified: true,
    };
    temp[i] = value;
    setImages(temp);
    if (i + 1 === images.length) {
      let value = {
        id: null,
        src: "https://cooking-test.s3.us-east-2.amazonaws.com/tattooImage/default.png",
      };
      setImages((images) => {
        return [...images, value];
      });
    }
    // setValues({ ...values, [item]: value });
    // let t = image.hasOwnProperty(item);
    // setImage({
    //   ...image,
    //   [item]: value,
    // });
    // if (!t) {
    //   setImages([...images, item]);
    // }
  };
  const deleteImage = async (e, i) => {
    // setIsDelete(true);
    let tmp = images;
    let lists = await tmp.splice(i, 1);
    let t = { id: lists[0]._id };
    setId((ids) => {
      return [...ids, lists[0]._id];
    });
  };
  const onUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    let t = null;
    let formData = new FormData();
    formData.append("description", values.description);
    formData.append("name", values.name);
    formData.append("prixUnitaire", values.prixUnitaire);
    formData.append("prixAchat", values.prixAchat);
    formData.append("unite", values.unite);
    images.map((el) => {
      if (el._id === 1) {
        formData.append("files", el.src);
      }
    });
    ids.map((el) => {
      if (el !== 1) {
        formData.append("deleteId", el);
      }
    });
    await updateMateriel(id, formData);
    setLoading(false);
    setOpen(true);
  };
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="mb-5 text-2xl font-semibold text-gray-900">
              Update Materiels
            </h1>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-2 md:gap-6">
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form action="#" method="POST">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="nom"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Nom
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="nom"
                          autoComplete="given-name"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          value={values.name}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="unite"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Unite
                        </label>
                        <input
                          type="text"
                          name="unite"
                          id="unite"
                          autoComplete="family-name"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          value={values.unite}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="prixAchat"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Prix d'achat
                        </label>
                        <input
                          type="number"
                          name="prixAchat"
                          id="prixAchat"
                          autoComplete="given-name"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          value={values.prixAchat}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="prixVente"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Prix de vente
                        </label>
                        <input
                          type="number"
                          name="prixUnitaire"
                          id="prixUnitaire"
                          autoComplete="family-name"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          value={values.prixUnitaire}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-span-6">
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Description
                        </label>
                        <p className="mb-2 text-sm text-gray-500">
                          Quelques le ligne pour decrire le produit (optionnel).
                        </p>
                        <textarea
                          id="description"
                          name="description"
                          rows={3}
                          className="max-w-full shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                          value={values.description}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="mb-2 flex flex-wrap mt-1 sm:mt-0 sm:col-span-2">
                      {images.length === 0 ? (
                        <div></div>
                      ) : (
                        <div className="m-2 flex flex-wrap mt-1 sm:mt-0 sm:col-span-2">
                          {images.map((img, i) => (
                            <label
                              key={i}
                              style={{ width: 200, height: 110 }}
                              className="m-2 items-center justify-center  border-2 mb-2  border-current hover:bg-gray-100 hover:border-current"
                            >
                              <div className="flex flex-col items-center justify-center ">
                                <div
                                  className=" items-center justify-center "
                                  style={{
                                    width: 200,
                                    height: 100,
                                    textAlign: "left",
                                    justifyContent: "left",
                                    alignItems: "left",
                                  }}
                                >
                                  {!img._id ? null : (
                                    <div
                                      style={{
                                        position: "absolute",
                                        // alignItems: "left",
                                        // textAlign: "left",
                                        // width: 20,
                                        // height: 20,
                                      }}
                                      type="button"
                                      className="bg-red-500 items-left border border-transparent rounded-full shadow-sm text-white bg-current hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                      <TrashIcon
                                        onClick={(e) => {
                                          e.preventDefault();
                                          deleteImage(e, i);
                                        }}
                                        className="h-5 w-5 bg-red-500"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  )}
                                  <img
                                    style={{
                                      alignItems: "center",
                                      width: 200,
                                      height: 110,
                                    }}
                                    className=" flex items-center justify-center "
                                    src={
                                      // Object.values(img).length === i
                                      !img.modified
                                        ? img.src
                                        : URL.createObjectURL(img.src)
                                    }
                                    alt={"img"}
                                  />
                                </div>
                              </div>
                              <input
                                type="file"
                                className="opacity-0"
                                accept="image/*"
                                onChange={(e) => {
                                  if (e.target.files.length > 0) {
                                    e.preventDefault();
                                    updateField(img, e, i);
                                  }
                                }}
                              />
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="pt-5">
                      <div className="flex justify-start">
                        <Link
                          to={`/dashboard/materiels/${id}`}
                          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Annuler
                        </Link>
                        <button
                          onClick={(e) => onUpdate(e)}
                          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Enregistrer
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Succès
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Materiel modifié avec succès
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <Link
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    to={`/dashboard/materiels/${id}`}
                  >
                    ok
                  </Link>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
