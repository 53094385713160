import React from "react";
import { Link, useLocation } from "react-router-dom";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavigComponent() {
  const { pathname } = useLocation();
  return (
    <div className="text-center mb-2 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <span className="mb-2 relative z-0 inline-flex shadow-sm rounded-md">
        <Link
          type="button"
          to="/dashboard/commandes"
          className={classNames(
            pathname === "/dashboard/commandes"
              ? "bg-orange-600 text-white hover:bg-orange-700"
              : "text-gray-700 hover:text-gray-900 hover:bg-gray-50 ",
            " mr-2 ml-px relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          )}
        >
          Devis en cours
        </Link>
        <Link
          type="button"
          to="/dashboard/commandes-traitees"
          className={classNames(
            pathname === "/dashboard/commandes-traitees"
              ? "bg-green-600 text-white hover:bg-green-700"
              : "text-gray-700 hover:text-gray-900 hover:bg-gray-50 ",
            " mr-2 ml-px relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          )}
        >
          Devis traitées
        </Link>
      </span>
    </div>
  );
}
