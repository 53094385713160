import React from "react";

const SectionThree = () => {
  return (
    <div className="bg-gray-50 pt-15 sm:pt-2">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-6">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Quelques chiffres !!
          </h2>
          <p className="mt-3 text-xl text-gray-500 sm:mt-4">
            Toutes nos équipes se battent au Quotidien pour satisfaire le besoin
            en livraison à l'heure des chantiers et à la Qualité de pose.
          </p>
        </div>
      </div>
      <div className="mt-10 pb-12 bg-white sm:pb-16">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-gray-50" />
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
              <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    Délais
                  </dt>
                  <dd className="order-1 text-5xl font-extrabold text-red-600">
                    99%
                  </dd>
                </div>
                <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    Service client
                  </dt>
                  <dd className="order-1 text-5xl font-extrabold text-red-600">
                    24/7
                  </dd>
                </div>
                <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    Projets
                  </dt>
                  <dd className="order-1 text-5xl font-extrabold text-red-600">
                    +150
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
