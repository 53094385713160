import { BookOpenIcon } from "@heroicons/react/outline";
import React from "react";
import { Link } from "react-router-dom";

const LinkShop = () => {
  return (
    <div className="absolute z-50 flex justify-center ml-5 ">
      <div className="mt-5  text-center">
        <Link
          className="text-orange-600 font-semibold underline text-xl"
          to="/produits"
        >
          <span className="flex gap-2">
            {" "}
            <BookOpenIcon height={30} /> Notre Catalogue et Gammes de Produits
          </span>
        </Link>
      </div>
    </div>
  );
};

export default LinkShop;
