import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const monthNames = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "décembre",
];

export default function GraphMonth({ day, month }) {
  const getMonthName = (d) => {
    let data = monthNames[d.getMonth()];
    return data;
  };

  const monthData = month.map((element) => {
    return {
      id: 0,
      MontantTotal: element.MontantTotal,
      label: getMonthName(new Date(element._id)),
    };
  });

  return (
    <div>
      <div className="grid grid-cols-1">
        <div style={{ width: "90%", height: 450 }}>
          <ResponsiveContainer>
            <LineChart
              width={600}
              height={300}
              data={monthData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="label" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="MontantTotal"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
