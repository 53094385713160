/* This example requires Tailwind CSS v2.0+ */
// import Header from "../../components/Home/Header";
import SectionTwo from "../../components/Home/SectionTwo";
import SectionThree from "../../components/Home/SectionThree";
import SectionFour from "../../components/Home/SectionFour";
import FeatureSection from "../../components/Home/FeatureSection";
import Animation from "../../components/shared/Animation";
import LinkShop from "./LinkShop";

export default function Home() {
  return (
    <>
      <Animation />
      <LinkShop />
      <SectionTwo />
      <FeatureSection />
      <SectionThree />
      <SectionFour />
    </>
  );
}
