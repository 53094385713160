import React, { useState } from "react";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { sendContactMail } from "../../api/statistiqueApi";
import Loader from "../../components/shared/Loader";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

const FormAbout = () => {
  const [loading, setLoading] = useState(false);
  const [dataForm, setDataForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const onsubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await sendContactMail(dataForm);
      setDataForm({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
      setLoading(false);
      NotificationManager.success(
        "Votre Message a ete envoye avec success nous vous repondrons dans les plus bref delai",
        "Succès",
        200
      );
    } catch (error) {}
    setLoading(false);
  };
  console.log("Loading", loading);

  const handleChange = (items, value) => {
    setDataForm({ ...dataForm, [items]: value });
  };

  return (
    <div className="relative bg-white">
      <NotificationContainer />
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-100 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12 rounded-xl">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              Prenons Contact !
            </h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Notre équipe est disponible pour toutes vos questions technique ou
              commerciale, ...
            </p>
            <dl className="mt-8 text-base text-gray-600">
              <div>
                <dt className="sr-only">Adresse :</dt>
                <dd>
                  {/* <p>90 Bd chevalier</p> */}
                  <p>38110 La Tour du Pin</p>
                  <p>Secteur : France et Suisse (Genève et Alentours) </p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Téléphone</dt>
                <dd className="flex">
                  <PhoneIcon
                    className="flex-shrink-0 h-6 w-6 text-red-500"
                    aria-hidden="true"
                  />
                  <span className="ml-3">+336 41 08 55 57</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon
                    className="flex-shrink-0 h-6 w-6 text-red-500"
                    aria-hidden="true"
                  />
                  <span className="ml-3">contact@senepave.com</span>
                </dd>
              </div>
            </dl>
            <p className="mt-6 text-base text-gray-500">
              En Recherche d'opportunitées ? <br />
              <Link
                to="#"
                className="font-medium text-gray-400 hover:underline"
              >
                Nos Offres d'emplois ... Bientôt
              </Link>
              .
            </p>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <form action="#" method="POST" className="grid grid-cols-1 gap-y-6">
              <div>
                <label htmlFor="full-name" className="sr-only">
                  Nom - Prénom
                </label>
                <input
                  type="text"
                  name="full-name"
                  id="full-name"
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-red-400 focus:border-red-400 border-gray-300 rounded-md"
                  placeholder="Nom - Prénom"
                  value={dataForm.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-red-400 focus:border-red-400 border-gray-300 rounded-md"
                  placeholder="Email"
                  value={dataForm.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="phone" className="sr-only">
                  Téléphone
                </label>
                <input
                  type="number"
                  name="phone"
                  id="phone"
                  autoComplete="tel"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-red-400 focus:border-red-400 border-gray-300 rounded-md"
                  placeholder="Téléphone"
                  value={dataForm.phone}
                  onChange={(e) => handleChange("phone", e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  Votre message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-red-400 focus:border-red-400 border border-gray-300 rounded-md"
                  placeholder="Message"
                  value={dataForm.message}
                  onChange={(e) => handleChange("message", e.target.value)}
                />
              </div>
              {dataForm.name === "" ||
              dataForm.email === "" ||
              dataForm.phone === "" ? (
                <div>
                  <p className="text-red-500 ">
                    " Merci de remplir le formulaire complet avant de soumettre
                    ... "
                  </p>
                </div>
              ) : (
                <div>
                  {loading ? (
                    <Loader />
                  ) : (
                    <button
                      type="submit"
                      className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400"
                      onClick={onsubmit}
                    >
                      Envoyé
                    </button>
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAbout;
