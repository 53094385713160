import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import formatMoney from "../../utils/FormatMoney";

export default function TableauFacture({ facture, index }) {
  useEffect(() => {
    console.log(facture);
  }, []);
  function getSum(array, column) {
    let values = array.map((item) => parseInt(item[column]) || 0);
    let montant = values.reduce((a, b) => a + b);
    return formatMoney(montant);
  }

  return (
    <tr
      key={facture._id}
      className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
    >
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {"0000".substr(String(facture.numeroFacture).length) +
          facture.numeroFacture}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {facture.nomClient}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {facture.email}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {facture.commandes ? getSum(facture.commandes, "sousTotal") : 0} €
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <Link
          to={`/dashboard/factures/${facture._id}`}
          className=" inline-flex items-center px-2 py-1 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Détail
        </Link>
      </td>
    </tr>
  );
}
