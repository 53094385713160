import React from "react";
import { Link } from "react-router-dom";
import formatMoney from "../../utils/FormatMoney";

export default function Product2Card({ materiel }) {
  // console.log("materiel", materiel);
  return (
    <div className="text-center w-40">
      <Link to={`/materiel/${materiel._id}`} type="button">
        <div className=" h-36 w-full rounded-md overflow-hidden group-hover:opacity-75 mx-auto cursor-pointer hover:h-38 hover:border-4 hover:border-gray-400">
          <img
            src={materiel.images[0].src}
            alt={materiel.name}
            className="w-full h-full object-center object-cover"
            onClick={() => {
              console.log(materiel._id);
              // window.location.replace(`/materiel/${materiel._id}`);
            }}
          />
        </div>

        <span className="absolute text-xs " />
        <h4 className="mt-4 text-gray-700 uppercase">{materiel.name}</h4>

        <p className="mt-1 text-lg font-small text-gray-900">
          {formatMoney(materiel.prixUnitaire)} € /{" "}
          <span className="italic"> {materiel.unite}</span>
        </p>
      </Link>
    </div>

    //Old Code
    // <div className="text-center border-2 border-blue-100 p-2 rounded-lg ">
    //   <li key={materiel.id} className="relative">
    //     <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
    //       <img
    //         src={materiel.images[0].src}
    //         alt=""
    //         className="object-cover pointer-events-none group-hover:opacity-75"
    //       />
    //       <Link
    //         to={`/materiel/${materiel._id}`}
    //         type="button"
    //         className="absolute inset-0 focus:outline-none"
    //       >
    //         <span className="sr-only">Voir le détail de {materiel.name}</span>
    //       </Link>
    //     </div>
    //     <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none text-center uppercase ">
    //       {materiel.name}
    //     </p>
    //   </li>
    // </div>
  );
}
