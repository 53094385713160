import React from "react";
import { Link } from "react-router-dom";

export default function MaterielCard({ file }) {
  return (
    <div className="text-center border-2 border-blue-100 p-2 rounded-lg ">
      <li key={file.id} className="relative">
        <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
          <img
            src={file.images[0].src}
            alt=""
            className="object-cover pointer-events-none group-hover:opacity-75"
          />
          <Link
            to={`/dashboard/materiels/${file._id}`}
            type="button"
            className="absolute inset-0 focus:outline-none"
          >
            <span className="sr-only">Voir le détail de {file.name}</span>
          </Link>
        </div>
        <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none text-center uppercase ">
          {file.name}
        </p>
      </li>
    </div>
  );
}
