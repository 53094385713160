import React from "react";
import { Link } from "react-router-dom";
import formatMoney from "../../utils/FormatMoney";

export default function ProductCard({ pave }) {
  return (
    <div className="text-center w-40 border-2 rounded-lg m-4">
      <Link to={`/produitdetail/${pave._id}`}>
        <div className=" h-32 w-full rounded-sm overflow-hidden group-hover:opacity-75 mx-auto hover:h-38 hover:border-4 hover:border-gray-400">
          <img
            src={pave.images[0].src}
            alt={pave.name}
            className="w-full h-full object-center object-cover"
            onClick={() => console.log("id Pavé", pave._id)}
          />
        </div>
        <div className="h-0.5 bg-slate-200"> </div>
        <h4 className="mt-4 text-gray-700 uppercase">{pave.nomPave}</h4>
        <p className="mt-1 text-lg font-small text-gray-900">
          {formatMoney(pave.prixVente)} € / m2
        </p>
      </Link>
    </div>
  );
}
